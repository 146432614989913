
.callout {
  align-items: center;
  display: flex;
  border-radius: 1em;
  gap: 1em;
  padding: 1em;
  padding-left: 1.25em;
  background: var(--stk-primary-90);
  color: var(--stk-primary-30);

  p {
    line-height: 1.25;
  }
}

.modal-content {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 20px;
  overflow-y: auto;
}

.full-screen-content {
  height: calc(100vh - 40px); /* Account for padding */
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal.is-active {
  z-index: 1000;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.86);
}

/* Outer container keeps everything together */
.upload-container {
  margin: auto;
  padding: 2rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Slight elevation */
}

/* Info Banner Styling */
.info-banner {
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
}

/* Ensures list stays readable */
.info-list {
  text-align: left;
  max-width: 600px;
  margin: auto;
}

/* Upload Button Section */
.upload-box {
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  text-align: center;
  margin-top: 1rem;
}

/* Make sure icons & text inside the upload button are centered */
.file-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
}

/* Larger Upload Icon */
.file-icon {
  font-size: 2rem;
}

.auto-fill-banner {
  background-color: var(--stk-primary-90);
  color: var(--stk-primary-30);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1em;
  padding: 1em 1.25em;
}
