
.expand-x {
  position: relative;
  overflow: hidden;
  transition: width 0.5s ease;
  height: 100%;
  /* border: 2px dashed black; */
}
.content {
  position: absolute;
  height: 100%;
}
