
.card {
  overflow: visible;
}
.screen-detail-pane.bottom.box {
  height: 40%;
}
.values-sets-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 1rem;
}

.field.has-addons {
  max-width: 600px;
  margin: 2rem auto;
  
  .input {
    border-radius: 20px;
    padding-left: 2.5rem;
    height: 2.5rem;
    border: 1px solid #e1e1e1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.02);
    
    &:focus {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      border-color: #b8b8b8;
    }
  }
  
  .icon.is-left {
    height: 2.5rem;
    width: 2.5rem;
  }
}

/* Add some spacing between cards */
.values-sets-container > * {
  margin-bottom: 1rem;
}
