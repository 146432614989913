
.stk-pills {
  background-color: var(--stk-text-95);
  padding: 0.5em;
  border-radius: 0.75em;
  gap: 0.25em;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;

  &.is-fullwidth {
    .stk-pills-list,
    .stk-pill-item {
      flex-grow: 1;
    }
  }
}

[data-stk-theme="dark"] {
  .stk-pills {
    background-color: var(--stk-text-20);
  }
}
