
.stk-callout {
  --background-color: var(--stk-text-95);
  --color: var(--stk-text-30);
  background-color: var(--background-color);
  color: var(--color);
  display: flex;
  align-items: start;
  justify-content: flex-start;
  font-weight: 600;
  line-height: 1.25;
  padding: 0.5em 0.75em;
  border-radius: 0.75em;
  gap: 0.5em;

  &.is-primary {
    --background-color: var(--stk-primary-90);
    --color: var(--stk-primary-30);
  }

  &.is-success {
    --background-color: var(--stk-success-90);
    --color: var(--stk-success-30);
  }

  &.is-info {
    --background-color: var(--stk-info-95);
    --color: var(--stk-info-30);
  }

  &.is-warning {
    --background-color: var(--stk-warning-90);
    --color: var(--stk-warning-30);
  }

  &.is-danger {
    --background-color: var(--stk-danger-90);
    --color: var(--stk-danger-30);
  }
}

.stk-callout-body {
  font-weight: 500;
}
