
  .page {
    /* Center the page on the screen */
    border: 1px solid #000;
    /* Define a border so you can see the page's dimensions */
    background-color: #fff;
    /* Set the background color to white */
    box-sizing: border-box;
    /* Include padding and border in element's total width and height */
    overflow: visible;
  }
  .pagewidth {
    max-width: 70em;
    min-width: 50em;
    margin: auto;
  }
  .pagecontent {
    padding: 0.5in;
    /* Provide a bit of space around the content */
  }
  .niceblue {
    background-color: #0a0b4f;
    background-image: linear-gradient(141deg, #1e4182 0, #0a0b4f 71%, #20217e 100%);
    color: white;
  }
  .pro-blue {
    background-color: rgb(15, 7, 43);
    background-image: linear-gradient(141deg, rgb(15, 7, 43), rgb(33, 13, 100), rgb(18, 9, 53));
    color: white;
  }
  .niceblue>p,
  .niceblue>.title,
  .niceblue>.subtitle {
    color: white;
  }
  .highlightbox {
    border: 2px solid #3C60E2;
    border-radius: 5px;
    padding: 10px;
  }
  .paperwidth {
    margin: auto;
    width: 8.5in;
  }
  .stickysection {
    position: sticky;
    z-index: 10;
    border: 2px solid white;
    border-radius: 5px;
    background-color: inherit;
  }
  .pagewidth {
    max-width: 80em;
    min-width: 50em;
  }
  .greentop {
    border-top: 3px solid green;
  }
  .yellowtop {
    margin-top: 1.5em;
    border-top: 3px solid yellow;
  }
  .redtop {
    border-top: 3px solid red;
  }
  .tabs.color-tips  li {
    margin-right: .5em;
  }
  .tabs.color-tips  a {
    border-top: 3px solid green;
  }
  .sticky-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
}
.primary-top {
  margin-top: 1em;
}
.moneysaved {
  border: 3px solid #3C60E2;
  border-radius: 9px;
  padding: .5em;
  padding-left: 2em;
}
.whitebackground {
  background-color: white;
  color: black;
}
.off-white-background {
  background-color: whitesmoke;
  color: black;
}
.chatborder {
  border: 3px solid purple;
  border-radius: 9px;
  padding: .5em;
}
.tightcolumns > .columns, .tightcolumns > .columns > .column {
  margin: 0;
  padding: 0
}
.smile {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.smile .button:nth-child(1) {
  align-self: flex-start;
}
.smile .button:nth-child(2) {
  align-self: center; 
}
.smile .button:nth-child(3) {
  align-self: center;
}
.smile .button:nth-child(4) {
  align-self: flex-start;
}
.maxwidth {
  max-width: 30em;
}
#upside-tabs {
  display: flex;
  flex-direction: column-reverse;
}
.bumpup {
  margin-top: -1em;
}
.greybackground {
  background-color: 	#FAF9F6;
}
.lightbox {
  border: 3px solid rgb(200,203,209);
  padding: 1.5em;
  border-radius: 15px;
  margin-top: -2.25em;
}
.lightbox-shallow {
  border: 3px solid rgb(200,203,209);
  padding: 1.5em;
  border-radius: 15px;
  margin-top: -2.75em;
}
.documents-tab {
  padding: 1em;
}

.request-link-section {
  margin-bottom: 1.5em;
}

.drawer {
  margin-top: 1.5em;
  border-top: 1px solid #ddd;
  padding-top: 1em;
}

.filters {
  display: flex;
  gap: 1em;
  margin-bottom: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding: 0.5em 0;
  border-bottom: 1px solid #ddd;
}

.document-name {
  font-weight: bold;
}

.document-date {
  font-style: italic;
}

.modal-card {
  width: 600px;
}

.progress-tracker {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
}

.progress-tracker > div {
  flex: 1;
  text-align: center;
  padding: 0.5em;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-tracker > div.completed {
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
}

.icon {
  margin-left: 0.5em;
  color: #155724;
}
  