
.validation-box {
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.validation-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.rule-title-container {
  flex: 1;
}

.rule-description {
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.rule-name {
  font-size: 0.8rem;
  color: #666;
}

.status-tag {
  margin-left: 1rem;
}

.validation-details {
  font-size: 0.9rem;
}

.config-tab-content {
  min-height: 300px;
}
