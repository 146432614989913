
.row {
  background-color: var(--stk-scheme-main);
  align-items: start;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 1rem;
  border: 1px solid var(--stk-border);
  padding: 1.5rem;
  position: relative;
  margin-bottom: -1px;
  transition-duration: var(--stk-transition-duration);
  transition-property: background-color, border-color, box-shadow;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 10rem;

  &:hover {
    border-color: var(--stk-primary);
    box-shadow: 0 0 0 1px var(--stk-primary);
    z-index: 1;
  }
}

.vendor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding-top: 0.125rem;
}
