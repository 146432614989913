
.user-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
  gap: 0.25em;
}

.line {
  font-size: 18px;
  color: #808080;
}

.name {
  font-size: 14px;
}

a {
  color: inherit;
  /* border: 1px dashed blue; */
}

a:hover {
  color: #3a66ac;
}
