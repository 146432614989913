
.body {
  --offset: 1rem;
  align-items: start;
  display: flex;
  gap: 2rem;
  margin-top: calc(-1 * var(--offset));
}

.side {
  flex-shrink: 0;
  position: sticky;
  top: 0;
  width: 14rem;
  padding-top: var(--offset);
}

.main {
  flex-grow: 1;
  padding-top: var(--offset);
  min-height: 40rem;

  &.edit-mode {
    background-color: #FCFCFD;
  }
}

.master-upload {
  width: 60rem;
}

.print-radio {
  cursor: pointer;
}

.print-box {
  padding: 1em;
}

@media print {
  .component {
    font-size: 0.625rem;
    padding: 0 !important;
  }

  .header,
  .onboarding,
  .side
  {
    display: none !important;
  }
}
