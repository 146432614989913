
.stk-buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;

  &.has-no-wrap {
    flex-wrap: nowrap;
  }

  &.is-vertical {
    align-self: stretch;
    align-items: stretch;
    flex-direction: column;
    justify-content: center;
  }
}
