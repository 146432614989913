

.fund-screener {
  .th-wrap > span {
    width: 100%;
  }

  .tabs.is-toggle li.is-active a {
    background-color: var(--black-95);
    border-color: var(--black-95);
  }
  h1 {
    color: red;
  }
  .fund-screening-grid {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 12rem auto;
    grid-template-areas:
      "filters metadata"
      "results portfolio";
    grid-gap: 1rem;
  }
  .filters {
    grid-area: filters;
  }
  .metadata {
    grid-area: metadata;
  }
  .results {
    grid-area: results;
  }
  .results-esg-mandate {
    max-width: 80vw;
    overflow: auto;

  }
  .portfolio {
    grid-area: portfolio;
  }
  .fund-detail-page {
    font-family: "DM Sans", "Helvetica Neue", "Roboto", sans-serif;
    color: rgb(49, 47, 48);
  }
  .metricard {
    width: 10rem;
    height: 7rem;
    padding: 0.5rem;
    margin: 0.5rem;
  }
  .metricflex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
  }
  .jonbox {
    border-radius: 5px;
    border: solid 1px #d8d8d8;
  }
  .jonboxcolumn {
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    padding: 1.25rem;
    display: block;
  }
  .jonbox2 {
    /* three sided border, bottomless */
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top: solid 1px #d8d8d8;
    border-left: solid 1px #d8d8d8;
    border-right: solid 1px #d8d8d8;
  }
  .csscolumns {
    column-count: 4;
    column-gap: 2em;
    column-rule-style: none;
    column-rule-width: 1px;
    column-rule-color: var(--black-95);
    column-fill: auto;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .metric-slider-cards {
    display: flex;

    .metric-slider {
      width: 150px;
    }
  }

  .table td.has-bg-color {
    padding: 0;

    & > div {
      padding: 0.5em 0.75em;
    }
  }

  .filters {
    .disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .exchange-comparison {
    text-align: left;
    width: 600px;
    padding: 1rem;
    line-height: 1.4rem;

    .fund-name {
      min-height: 4.2rem;
    }

  }
}
.fund-screener-v2 tr,
.fund-screener-v2 td,
.fund-screener-v2 td > div {
  height: 100%;
}
.dropdown-content {
  padding: 0;
}
.input {
  background-color: #f6f7f8;
  border: none;
}
.table-wrapper {
  min-height: 60vh;
}
.b-slider {
  margin: 0;
  margin-top: 10px;
}
// .b-slider-thumb {
//   max-width: 10px;
//   max-height: 10px;
//   span {
//     font-size: 0.8em;
//   }
// }
