
/* Need to use a Sass variable because Webpack
doesn't like the new relative color syntax
https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_colors/Relative_colors */
$bg: 'rgb(from var(--color) r g b / 15%)';

.component {
  --color: #577EFF;
  background-color: unquote($bg);
  border-radius: 1rem;
  color: var(--color);
  padding: 2rem 2.5rem;
}

.second {
  font-weight: 600;
}

.option {
  border: 1px dashed var(--color);
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header,
.list,
.vendor,
.count {
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: start;
}

.count {
  border: 1px solid var(--color);
  border-radius: 9999px;
  justify-content: center;
  height: 1.5em;
  width: 1.5em;
}

.name {
  color: var(--stk-text-strong);
  font-weight: 600;
  line-height: 1;
  font-size: 1.125em;
}

.list {
  flex-wrap: wrap;
  padding-left: 2em;
}

.vendor {
  color: var(--stk-text-strong);
}

.vendor-logo {
  height: 1.25em;
  width: 1.25em;

  &.is-salesforce {
    width: 2em;
  }
}

.option.is-connected {
  border-color: var(--stk-success-50);

  .count {
    color: var(--stk-success-50);
    border-width: 0;
  }

  .name {
    opacity: 0.3;
    text-decoration: line-through;
  }
}

.toggle {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.component.is-expanded {
  .toggle {
    float: right;
  }
}

.component.is-collapsed {
  display: flex;
  cursor: pointer;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  .name {
    font-size: 1em;
  }

  &:hover .toggle {
    text-decoration: underline;
  }
}

.dashboard {
  width: 60em;
}
