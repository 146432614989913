
.importance-5 {
  background-color: #c3e6cb !important;
  color: #155724 !important;
}
.importance-4 {
  background-color: #d4edda !important;
  color: #155724 !important;
}
.drawer-content {
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.cell[data-header="meeting_context"] {
  display: none;
}

.bold-text {
  font-weight: bold;
}
