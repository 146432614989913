
#ips {
  width: 50em;
}
section {
  padding-bottom: 1em;
}
h3 {
  font-weight: bold;
}
h4 {
  text-decoration: underline;
}
p {
  padding-bottom: 1em;
}

/* .inserted-text {
  background-color: lightsalmon;
} */
