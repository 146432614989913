
.bottom.box {
  width: 70%;
  box-shadow: 0px -5px 17px 2px rgba(0,0,0,0.32);
  z-index: 500;
  position: fixed;
  transition: bottom 500ms;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100%;
  height: 90%;

}

.active .bottom.box {
  bottom: 0%;
  overflow: scroll;
  display: block;
  z-index: 101;
}

.bottom.box.peek.full {
  bottom: 0%;
  overflow: scroll;
  max-height: 100%;
}

.bottom.box .caret {
  text-align: center;
  cursor: pointer;
}

.switch-container {
  .switch-widget {
    display: block;
    margin: 0 auto;
    text-align: center;

    .check {
      margin: 0 auto;
    }
  }
}

#detailedTable {
  td.truncated {
    max-width: 30ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
