
.name {
  width: 20%;
}

.status {
  width: 10em;
}

.date {
  font-size: 0.875em;
  width: 9em;
}

.classification {
  width: 10em;
}

.summary {
  width: 30em;
}

.actions {
  width: 14em;
}
