
.table-status {
  width: 80px;
}

.table-reason {
  width: 300px;
}

.status-check-plus {
  background-color: #C8E6C9;
}

.status-x {
  background-color: #FCE4A7;
}

table th {
  color: white;
}

textarea:focus, input:focus {
  outline: 1px solid #00345d;
}

textarea {
  overflow-y: hidden;
  min-height: 40px;
  line-height: 1.5;
  padding: 8px 0;
}

button {
  cursor: pointer;
}
