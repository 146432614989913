
.fund-container {
  margin-bottom: 1em;
  border: 1px solid #e5e5e5;
  padding: 1em;
  border-radius: 5px;
}

.strategy-narrative {
  text-align: justify;
  margin: 1em 0;
}

.strategy-narrative span {
  color: #333;
  font-family: 'Arial', sans-serif;
  line-height: 1.5em;
}

ul {
  list-style-type: none;
  padding: 0;
}
