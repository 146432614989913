
.horizontalflex {
  display: flex;
  flex-direction: row;
  justify-content: center;

}
.horizontalflex > * {
  margin: 1rem;
}
.underlined {
  border-bottom: 3px solid black;
}

.screen-set-container {
  min-width: 1000px;
}

.metric-description-container {
  width: 40%;
  min-width: 40%;
}

.screen-set.metric-description-container {
  min-width: 60%;
}

.base-container {
  min-height: 1000px;
}

