
.controls,
.control {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.dossier-input {
  width: 100%;
}

.clear-control {
  margin-left: 0.5rem;
}
