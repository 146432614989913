
.choose-screenset {

  .modal.is-active {

    .modal-card {
      background-color: #fff;
      align-self: center;

      .b-table {
        max-height: 50vh;
        overflow: scroll
      }
    }
  }

}
