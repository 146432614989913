
.modal-card {
  max-width: 480px;
}

.content {
  h4 {
    margin-bottom: 0.5rem;
  }
}
