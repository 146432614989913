
section > h2 {
  @apply tw-font-bold tw-text-lg;
  @apply tw-mb-1;
}
section > div {
  @apply tw-py-4;
  @apply tw-border-0 tw-border-t tw-border-solid tw-border-gray-300;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.15s;
}
.fade-enter, .fade-leave-to, .fade-slow-leave-to {
  opacity: 0;
}
.fade-slow-leave-active {
  transition: opacity 0.5s ease;
}
