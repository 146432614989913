
.tw-tree-container {
  position: relative;
}

.tw-tree-node {
  position: relative;
  padding-bottom: 20px;
}

.tw-tree-node::before {
  content: '';
  position: absolute;
  top: 12px;
  left: 6px;
  bottom: 0;
  width: 2px;
  background: #e2e8f0;
}

.tw-tree-node:last-child::before {
  display: none;
}

.form-checkbox {
  @apply tw-rounded;
}

.tw-transition-colors {
  @apply tw-transition-all tw-duration-200 tw-ease-in-out;
}
