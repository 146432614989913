
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
  }
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
  }


  .page {
    /* Center the page on the screen */
    border: 1px solid #000;
    /* Define a border so you can see the page's dimensions */
    background-color: #fff;
    /* Set the background color to white */
    box-sizing: border-box;
    /* Include padding and border in element's total width and height */
    overflow: visible;
  }
  .pagewidth {
    max-width: 70em;
    min-width: 50em;
    margin: auto;

  }

  .pagecontent {
    padding: 0.5in;
    /* Provide a bit of space around the content */
  }

  .niceblue {
    background-color: #0a0b4f;
    background-image: linear-gradient(141deg, #1e4182 0, #0a0b4f 71%, #20217e 100%);
    color: white;
  }
  .pro-blue {
    background-color: rgb(15, 7, 43);
    background-image: linear-gradient(141deg, rgb(15, 7, 43), rgb(33, 13, 100), rgb(18, 9, 53));
    color: white;
  }

  .niceblue>p,
  .niceblue>.title,
  .niceblue>.subtitle {
    color: white;
  }

  .highlightbox {
    border: 2px solid #3C60E2;
    border-radius: 5px;
    padding: 10px;
  }


  .paperwidth {
    margin: auto;
    width: 8.5in;

  }

  .stickysection {
    position: sticky;
    top: 0;  /* This is crucial - defines where it sticks */
    z-index: 10;
    border: 2px solid white;
    border-radius: 5px;
    background-color: inherit;
    /* Add height constraints */
    max-height: 80vh;
    padding: 1rem;
  }
  .pagewidth {
    max-width: 80em;
    min-width: 50em;
  }
  .greentop {
    border-top: 3px solid green;
  }
  .yellowtop {
    border-top: 3px solid yellow;
  }
  .redtop {
    border-top: 3px solid red;
  }
  .tabs.color-tips  li {
    margin-right: .5em;
  }
  .tabs.color-tips  a {
    border-top: 3px solid green;
  }
  .sticky-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
}
.whitebackground {
  background-color: white;
  color: black;
}
.off-white-background {
  background-color: whitesmoke;
  color: black;
}
.chatborder {
  border: 3px solid purple;
  border-radius: 9px;
  padding: .5em;
}
.tightcolumns > .columns, .tightcolumns > .columns > .column {
  margin: 0;
  padding: 0
}
  