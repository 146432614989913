
.flow-card {
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.03);
  border-radius: 11px;
}

.pagination-link.is-current {
  background-color: #485fc7;
  border-color: #485fc7;
  color: #fff;
}
