
.card {
  border: 1px solid #ccc;
  padding: 16px;
  margin-bottom: 16px;
  position: relative;
  border-radius: 4px;
}

.recommendation-card {
  border-left: 4px solid #27ae60; /* Green for recommendations */
  background-color: #f0f9f4;
}

.consideration-card {
  border-left: 4px solid #9b59b6; /* Purple for considerations */
  background-color: #f9f0f9;
}

.item-type-badge {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px;
  border-radius: 0 4px 0 4px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.item-type-badge.recommendation {
  background-color: #27ae60;
}

.item-type-badge.consideration {
  background-color: #9b59b6;
}

.tabs {
  display: flex;
  margin-bottom: 16px;
  border-bottom: 1px solid #ddd;
}

.tab-button {
  padding: 8px 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  color: #666;
}

.tab-button.active {
  border-bottom: 2px solid #3498db;
  color: #3498db;
}

.button-row {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}
