
.stk-loader {
  --stk-loader-color: currentColor;
  animation: spinAround 500ms infinite linear;
  border: 0.125em solid var(--stk-loader-color);
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  flex-shrink: 0;
  display: inline-block;
  flex-shrink: 0;
  height: 1em;
  position: relative;
  vertical-align: top;
  width: 1em;
}
