
  .horizontalflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    
  }
  .horizontalflex > * {
    margin: 1rem;
  }
  .underlined {
    border-bottom: 3px solid black;
  }

/* bookshelf glass */

.shelf {
  display: block;
  position: relative;
  width: 100%;
  height: 20px;
  margin: -10px auto 30px;
  z-index: 1;
  background-image: -moz-linear-gradient(90deg, #d1d8de 0%, #eff1f3 100%);
  background-image: -webkit-gradient(90deg, left top, right bottom, color-stop(0%, #d1d8de), color-stop(100%, #eff1f3));
  background-image: -webkit-linear-gradient(90deg, #d1d8de 0%, #eff1f3 100%);
  background-image: -o-linear-gradient(90deg, #d1d8de 0%, #eff1f3 100%);
  background-image: -ms-linear-gradient(90deg, #d1d8de 0%, #eff1f3 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eff1f3', endColorstr='#d1d8de', GradientType='0');
  -webkit-box-shadow: 0 2px 2px #708698, 0 4px 0 #abb8c3, 0 20px 30px -8px #000000, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0;
  -moz-box-shadow: 0 2px 2px #708698, 0 4px 0 #abb8c3, 0 20px 30px -8px #000000, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0;
  box-shadow: 0 2px 2px #708698, 0 4px 0 #abb8c3, 0 20px 30px -8px #000000, transparent 0 0 0, transparent 0 0 0, transparent 0 0 0;

  .reflection {
    display: block;
    position: absolute;
    top: 20px;
    left: 1px;
    width: 99.8%;
    height: 1px;
    background-image: -moz-linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0.5) 35%, #ffffff 65%, rgba(255, 255, 255, 0.7) 100%);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, #ffffff), color-stop(35%, rgba(255, 255, 255, 0.5)), color-stop(65%, #ffffff), color-stop(100%, rgba(255, 255, 255, 0.7)));
    background-image: -webkit-linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0.5) 35%, #ffffff 65%, rgba(255, 255, 255, 0.7) 100%);
    background-image: -o-linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0.5) 35%, #ffffff 65%, rgba(255, 255, 255, 0.7) 100%);
    background-image: -ms-linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0.5) 35%, #ffffff 65%, rgba(255, 255, 255, 0.7) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(255,255,255,0.7)', endColorstr='#ffffff', GradientType='0');
  }
}

.bookend_left {
  display: block;
  position: absolute;
  left: -25px;
  top: -18px;
  width: 36px;
  height: 36px;
  background-color: #ffffff;
  -webkit-transform: rotate(35deg);
  -moz-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  -o-transform: rotate(35deg);

  &:before {
    position: absolute;
    top: 31px;
    left: 17px;
    width: 20px;
    height: 10px;
    background-color: #ffffff;
    content: "";
    -webkit-transform: rotate(-35deg);
    -moz-transform: rotate(-35deg);
    -ms-transform: rotate(-35deg);
    -o-transform: rotate(-35deg);
  }
}

.bookend_right {
  display: block;
  position: absolute;
  right: -25px;
  top: -18px;
  width: 36px;
  height: 36px;
  background-color: #ffffff;
  -webkit-transform: rotate(-35deg);
  -moz-transform: rotate(-35deg);
  -ms-transform: rotate(-35deg);
  -o-transform: rotate(-35deg);

  &:before {
    position: absolute;
    top: 31px;
    right: 17px;
    width: 20px;
    height: 10px;
    background-color: #ffffff;
    content: "";
    -webkit-transform: rotate(35deg);
    -moz-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    -o-transform: rotate(35deg);
  }
}
.ontop {
  z-index: 10;
}
