
.adaptive-nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.1em 0.5em 0.1em 0.5em;
  transition: background-color 0.25s;
}

.full {
  border-radius: 0.2em;
}

.full:hover, .mini.link:hover {
  background-color: #cdd6e4;
  color: black;
}

.name {
  display: flex;
  flex-grow: 1;
  white-space: nowrap;
}

.name > * {
  flex-grow: 1;
  padding-right: 1em;
}

.active.header.full,
.active.header.full a,
.active.link {
  background-color: #3a66ac;
  color: white;
}

.header {
  font-size: 18px;
  font-weight: bold;
}

.pointer:hover {
  cursor: pointer;
}

a {
  color: inherit;
}
