
.entity-explorer-list-view {
    height: 100%;
    background-color: #1f1f1f;
    color: #e0e0e0;
}

:deep(.o-table) {
    background-color: transparent;
    color: #e0e0e0;
}

:deep(.o-table th) {
    background-color: #252525;
    color: #bbb;
    font-weight: 600;
    border-bottom: 1px solid #333;
}

:deep(.o-table td) {
    border-bottom: 1px solid #333;
}

:deep(.o-table-row:hover) {
    background-color: #2a2a2a;
}

:deep(.o-pagination) {
    background-color: #252525;
    color: #e0e0e0;
}

:deep(.o-pagination-link) {
    color: #4a9eff;
}

:deep(.o-pagination-link.is-current) {
    background-color: #4a9eff;
    border-color: #4a9eff;
    color: #fff;
}
