
.step-link {
  button {
    min-width: 200px;
    height: 75px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.03);
    border-radius: 11px;
  }
}
// .circle {
//   background: #36B779;
//   // opacity: 0.1;
//   // text-align: center;

//   // width: 30px;
//   // height: 30px;;
//   border-radius: 50%;
//   text-align: center;
//   vertical-align: middle;

// }
