
table {
  border-collapse: collapse;
  background-color: #f9f9f9; /* Light gray background for better contrast */
}

th, td {
  padding: 6px 9px; /* Padding for cell content */
  border: 1px solid #e0e0e0; /* Light gray border */
  text-align: left;
}

th:first-child, td:first-child {
    text-align: right;  /* This right-aligns the first column */
}

thead {
  background-color: #e0e0e0; /* Slightly darker header for distinction */
}

tbody tr:hover {
  background-color: #f1f1f1; /* Subtle hover effect */
}
