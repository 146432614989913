
button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  max-width: 100%;
}

button:focus {
  outline: none;
}

.fa-chevron-down,
.fa-chevron-right {
  transition: transform 0.2s;
}

.fa-chevron-down {
  transform: rotate(0deg);
}

.fa-chevron-right {
  transform: rotate(90deg);
}
