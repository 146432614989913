
.analysis-area-content > div > div {
  border: 1px dashed gray;
}
.analysis-area-columns > div {
    display: inline-block;
    width:   7rem;
  }

.moneysaved {
  border: 3px solid #3C60E2;
  border-radius: 9px;
  padding: .5em;
  padding-left: 2em;
}

