
.is-absolute { position: absolute; }

.is-flex-col {
  display: flex;
  flex-direction: column;
}
.is-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.is-flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Justify Content on Main Axis */
.justify-start   { justify-content: flex-start; }
.justify-end     { justify-content: flex-end; }
.justify-center  { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-around  { justify-content: space-around; }
.justify-even    { justify-content: space-evenly; }

/* Align Items on Cross Axis */
.align-start     { align-items: flex-start; }
.align-end       { align-items: flex-end; }
.align-center    { align-items: center; }
.align-stretch   { align-items: stretch; }
.align-c-start   { align-content: flex-start; }
.align-c-end     { align-content: flex-end; }
.align-c-center  { align-content: center; }
.align-c-stretch { align-content: stretch; }

/* Flex Gap between Items */
.gap-1-4 { gap: 0.25em; }
.gap-1-2 { gap: 0.5em; }
.gap-3-4 { gap: 0.75em; }
.gap-1   { gap: 1rem; }
.gap-2   { gap: 2rem; }
.gap-3   { gap: 3rem; }

/* Flex Grow and Shrink */
.grow { flex-grow: 1 }

/* Misc Flex */
.flex-wrap { flex-wrap: wrap; }
.basis-0 { flex-basis: 0; }

/* Sizing */
.width-100 { width: 100% }
.width-75  { width: 75% }
.width-50  { width: 50% }
.width-25  { width: 25% }

.page-width {
  max-width: 40rem;
}
.weight-bold {
  font-weight: bold;
}
.no-wrap {
  white-space: no-wrap;
}

/* Borders */
.border   { border: 1px solid gray; }
.border-1 { border: 1px solid gray; }
.border-2 { border: 2px solid gray; }
.border-3 { border: 3px solid gray; }

.border-rad-1-4 { border-radius: 0.25rem; }
.border-rad-1-2 { border-radius: 0.5rem; }
.border-rad-1   { border-radius: 1rem; }

.dev-border {
  border: 1px dashed black;
}
.curved-border {
  border-style: solid;
  border-width: 10px;
  border-color: transparent;
  border-radius: 10px
}

/* Text */
.text-white { color: white; }
.text-black { color: black; }
.text-gray  { color: gray; }

.text-left   { text-align: left; }
.text-center { text-align: center; }
.text-right  { text-align: right; }
.underlined  { text-decoration: underline; }

.is-extra-bold {
  font-weight: 900;
}
.is-heavy {
  font-weight: 600;
}
textarea:focus {
  outline: none;
}
