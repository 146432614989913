
.background {
  overflow-y: auto;
  padding: 3rem;
  z-index: 100;
}

.window {
  margin: auto;
  max-width: 40rem;
  overflow-x: hidden;
  overflow-y: hidden;
}

.no-max-width {
  max-width: none;
}
