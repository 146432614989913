
.wrapper {
  max-width: 35em;
  margin:    0 auto;
}

.title {
  font-size: 6rem;
}

.subtitle .highlight {
  font-weight: bold;
  color: #8c67ef;
}
