
/* Step container styles */
.step-container {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.step-container::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background: linear-gradient(to right, #ec4899, #8b5cf6);
  border-radius: 9999px;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.step-container.tw-border-3::before {
  opacity: 1;
}

/* Add any additional styles you need */
