
.card {
  border: 1px solid #ccc;
  padding: 16px;
  margin-bottom: 16px;
  position: relative;
  border-radius: 4px;
}

.observation-card {
  border-left: 4px solid #3498db; /* Blue for observations */
  background-color: #f8fafc;
}

.recommendation-card {
  border-left: 4px solid #27ae60; /* Green for recommendations */
  background-color: #f0f9f4;
}

.item-type-badge {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px;
  border-radius: 0 4px 0 4px;
  font-size: 12px;
  font-weight: bold;
  color: white;
}

.item-type-badge.observation {
  background-color: #3498db;
}

.item-type-badge.recommendation {
  background-color: #27ae60;
}

.tabs {
  display: flex;
  margin-bottom: 16px;
  border-bottom: 1px solid #ddd;
}

.tab-button {
  padding: 8px 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  color: #666;
}

.tab-button.active {
  border-bottom: 2px solid #3498db;
  color: #3498db;
}

.button-row {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

/* Comparison View Styles */
/* Simple Comparison View Styles */
.simple-comparison-view {
  border: 1px solid #eaeaea;
  border-radius: 4px;
  overflow: hidden;
}

.model-columns-container {
  display: flex;
  gap: 1px;
  background-color: #eaeaea;
}

.model-column {
  flex: 1;
  background-color: #fff;
}

.model-header {
  padding: 10px;
  font-weight: bold;
  text-align: center;
  background-color: #f1f5f9;
  border-bottom: 1px solid #eaeaea;
}

.model-section {
  padding: 10px;
  margin-bottom: 10px;
}

.section-header {
  font-weight: bold;
  padding: 5px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #eaeaea;
}

.model-item {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  position: relative;
}

.recommendation-item {
  background-color: rgba(39, 174, 96, 0.1);
  border-left: 3px solid #27ae60;
}

.observation-item {
  background-color: rgba(52, 152, 219, 0.1);
  border-left: 3px solid #3498db;
}

.item-headline {
  font-weight: bold;
  margin-bottom: 5px;
}

.item-detail {
  font-size: 0.9em;
  color: #666;
}

.empty-message {
  padding: 10px;
  color: #999;
  font-style: italic;
  text-align: center;
}
