
.tag-wrapper {
  margin: 0.75em;
}
.tag {
  text-align: left;
  display: inline-block !important;
  height: auto !important;
  white-space: unset !important;
  width: 100%
}
