
.section-legend-panel {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: var(--stk-text-15);
  border-left: 1px solid var(--stk-border);
  position: relative;
  z-index: 0;

  .panel-fixed-header {
    flex: 0 0 auto;
    padding: 1rem;
    background: var(--stk-text-15);
    border-bottom: 1px solid var(--stk-border);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 20;
  }

  .panel-title {
    color: var(--stk-text-strong);
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0;
  }

  .panel-scrollable-content {
    flex: 1;
    overflow-y: auto;
    padding: 0;
    position: relative;
    height: 100%;
  }
}

.section-item {
  background: var(--stk-text-20);
  border: 1px solid var(--stk-border);
  border-radius: var(--stk-border-radius);
  margin: 1rem;
  position: relative;

  .section-fixed-header {
    background: var(--stk-text-20);
    padding: 0.75rem;
    border-bottom: 1px solid var(--stk-border);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .section-title {
    color: var(--stk-text-strong);
    font-weight: 600;
    font-size: 1rem;
  }

  .section-fields {
    padding: 0.75rem;
    background: var(--stk-text-15);
    position: relative;
    z-index: 1;
  }

  &.is-processing {
    opacity: 0.7;
    pointer-events: none;
    
    .section-fields {
      display: none;
    }
  }
}

.field-item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  border: 1px solid var(--stk-border);
  border-radius: var(--stk-border-radius);
  margin-bottom: 0.5rem;
  transition: all 0.2s ease;
  z-index: 1;
  background: var(--stk-text-20);

  &:hover {
    border-color: var(--stk-primary);
    box-shadow: 0 2px 4px rgba(var(--stk-primary-rgb), 0.1);
    z-index: 2;
  }

  &.has-value {
    background: var(--stk-primary-10);
    border-left: 3px solid var(--stk-primary);
  }

  &.highlight-field {
    animation: highlightField 2s ease-out;
  }
}

@keyframes highlightField {
  0% {
    background-color: var(--stk-primary-20);
  }
  100% {
    background-color: var(--stk-text-20);
  }
}

.field-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.75rem;
}

.field-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.field-name {
  font-weight: 500;
  color: var(--stk-text-strong);
  flex: 1;
  min-width: 0;
}

.field-value {
  width: 100%;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    height: 32px;
    padding: 0.25rem 0.5rem;
    border: 1px solid var(--stk-border);
    border-radius: var(--stk-border-radius);
    font-size: 0.9rem;
    background: var(--stk-text-15);
    color: var(--stk-text-strong);

    &:focus {
      border-color: var(--stk-primary);
      box-shadow: 0 0 0 2px rgba(var(--stk-primary-rgb), 0.2);
      outline: none;
    }

    &::placeholder {
      color: var(--stk-text-weak);
    }
  }
}

.field-actions {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-left: 0.5rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    padding: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
    color: #4a90e2;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: #4a90e2;
      color: white;
      border-color: #4a90e2;
    }

    .icon {
      font-size: 0.9rem;
    }
  }
}

.field-details {
  padding: 1rem;
  background: var(--stk-text-15);
  border-top: 1px solid var(--stk-border);
  font-size: 0.875rem;
  line-height: 1.4;
  color: var(--stk-text);
}

.detail-item {
  margin-bottom: 1rem;
  padding: 0.75rem;
  background: var(--stk-text-20);
  border: 1px solid var(--stk-border);
  border-radius: var(--stk-border-radius);

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    color: var(--stk-text-strong);
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
}

.source-field {
  padding: 0.5rem;
  margin-top: 0.5rem;
  background: var(--stk-text-15);
  border-radius: var(--stk-border-radius);
  color: var(--stk-text);
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    margin-top: 0;
  }

  .source-field-key {
    font-weight: 500;
    color: var(--stk-text-strong);
  }

  .source-field-value {
    color: var(--stk-text);
    margin-left: 1rem;
    word-break: break-word;
  }
}

.transformation-text {
  font-style: italic;
  color: var(--stk-text);
  line-height: 1.4;
}

.confidence-value {
  font-weight: 600;
  color: var(--stk-primary);
}

.info-button {
  &.is-active {
    background: #4a90e2;
    color: white;
    border-color: #4a90e2;
  }
}

.section-loading-full {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: var(--stk-text-15);
  min-height: 100px;
  border-top: 1px solid var(--stk-border);
}

@media (max-width: 992px) {
  .section-legend-panel {
    width: 100% !important;
    margin-left: 0;
    margin-top: 1rem;
    max-height: 300px;
    z-index: 0;
  }
}
