
.working-portfolio {
  min-height: 400px;
}

.not-applicable {
  opacity: 0.5;
}

.holdings {
  .holding-controls {
    min-width: 50px;
    justify-content: left;
  }

  .holding-row {
    padding: 1rem;
  }

  .holding-row:nth-child(even) {
    background-color: #fbfbfb;
  }

  .holding-row.exchange-target {
    border-left: 5px solid hotpink;
  }

  .overall-contribution {
    text-align: center;
  }
}

