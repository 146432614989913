
.jonbox {
  padding: 1rem;
}
.tabs.is-toggle li.is-active a
{
  background-color: var(--black-95);
  border-color: var(--black-95);
}
h1 {
  color: red;
}
.fund-screening-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 12rem auto;
  grid-template-areas:
  "filters metadata"
  "results portfolio";
  grid-gap: 1rem;
}
.filters {
  grid-area: filters;
}
.metadata {
  grid-area: metadata;
}
.results {
  grid-area: results;
}
.portfolio {
  grid-area: portfolio;
}
.fund-detail-page {
  font-family: 'DM Sans', 'Helvetica Neue', 'Roboto', sans-serif;
  color: rgb(49,47,48);
}
.metricard {
  width: 10rem;
  height: 7rem;
  padding: .5rem;
  margin: .5rem;
}
.metricflex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: row;
}
.jonbox {
    border-radius: 5px;
    border: solid 1px #d8d8d8;
}
.jonboxcolumn {
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    padding: 1.25rem;
    display: block;
}
.jonbox2 {
  /* three sided border, bottomless */
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top: solid 1px #d8d8d8;
    border-left: solid 1px #d8d8d8;
    border-right: solid 1px #d8d8d8;
}
.csscolumns {
  column-count: 4;
  column-gap: 2em;
  column-rule-style: none;
  column-rule-width: 1px;
  column-rule-color: var(--black-95);
  column-fill: auto;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
.minheight {
  min-height: 5rem;
}

