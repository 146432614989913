
.icon.is-left {
  margin-right: 8px;
  color: #e74c3c; /* Optional: Change color to red */
}
.icon.is-left:hover {
  color: #c0392b; /* Darker red on hover */
}
.portfolio-list {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.portfolio-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.portfolio-name {
  flex-grow: 1;
  margin-right: 0.5rem;
}

.remove-button {
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  border-radius: 50%;
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
