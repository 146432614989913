
.pie-chart-table {
  margin-top: 20px;
  border-collapse: collapse;
  width: 100%;
}

.pie-chart-table th,
.pie-chart-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.pie-chart-table th {
  background-color: #f2f2f2;
  text-align: left;
}
