
.has-text-weight-extra-bold {
  font-weight: 900 !important;
}

.custom-tabs-container {
  display: inline-flex;
  background-color: #f3f4f6;
  padding: 0.25rem;
  border-radius: 9999px;
}

.custom-tabs :deep(.o-tabs-wrapper) {
  background-color: transparent;
}

.custom-tabs :deep(.o-tabs-item) {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  border-radius: 9999px;
  transition: all 0.3s ease-in-out;
  padding: 0.25rem 0.75rem;
}

.custom-tabs :deep(.o-tabs-item.is-active) {
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.custom-tabs :deep(.o-tabs-item .tab-label) {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
}

.custom-tabs :deep(.o-tabs-item.is-active .tab-label) {
  color: #2563eb;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .sidebar {
    margin-top: 1rem;
  }
}

.pdf-container {
  position: relative;
  width: 100%;
  height: 800px;
  background: white;
  border-radius: 4px;
}

.pdf-container :deep(iframe),
.pdf-container :deep(embed) {
  width: 100%;
  height: 100%;
  border: none;
}

.validation-rule-item {
  border-radius: 8px;
  border: 1px solid #eee;
}

.validation-rule-item:hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}


.tabs-wrapper {
  border-bottom: 1px solid #eee;
}


.validation-progress {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.validation-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.validation-chip {
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  border-radius: 999px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.validation-chip:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.validation-chip.is-success {
  background-color: #f0fdf4;
  color: #166534;
  border: 1px solid #dcfce7;
}

.validation-chip.is-danger {
  background-color: #fef2f2;
  color: #991b1b;
  border: 1px solid #fee2e2;
}

.validation-chip-label {
  margin-left: 0.5rem;
}

.highlight-rule {
  animation: highlight 2s ease-out;
}

@keyframes highlight {
  0% {
    background-color: #fff9c4;
  }
  100% {
    background-color: transparent;
  }
}

/* Make the validation chips responsive */
@media (max-width: 768px) {
  .validation-chips {
    flex-direction: column;
  }
  
  .validation-chip {
    width: 100%;
  }
}

.docusign-content {
  margin-top: 1rem;
}

.canvas-container {
  position: relative;
  width: 100%;
  overflow: auto;
}

.form-canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.pdf-container {
  background: white;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
}

.page-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.page-input {
  display: flex;
  align-items: center;
  margin: 0 1rem;
}

.validation-status-indicator {
  border-radius: 9999px;
  padding: 0.25rem 0.5rem;
}

/* New styles for the drawer layout */
.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-with-drawer {
  display: flex;
  position: relative;
  min-height: 500px;
}

.main-content {
  flex: 1;
  transition: width 0.3s ease;
  width: 100%;
}

.main-content.with-drawer {
  width: calc(100% - 350px);
}

.validation-drawer {
  width: 350px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  border-left: 1px solid #e5e7eb;
  box-shadow: -4px 0 6px -1px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  overflow-y: auto;
  z-index: 10;
  height: 100%;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 1rem;
}

/* Drawer animation */
.drawer-enter-active, .drawer-leave-active {
  transition: transform 0.3s ease;
}

.drawer-enter-from, .drawer-leave-to {
  transform: translateX(100%);
}

/* Make drawer full-width on mobile */
@media (max-width: 768px) {
  .main-content.with-drawer {
    width: 0;
    overflow: hidden;
  }
  
  .validation-drawer {
    width: 100%;
  }
}

/* New styles for validation summary in drawer */
.validation-summary {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.validation-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--stk-border-color);
}
