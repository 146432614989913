
.hackbars {
  height: 15px;
  background-color: #34a85c;

  &.left {
    background-color: #f14668;
    float:right;
  }
}
