
.entity-card {
  max-width: 1280px;
  margin: auto;
}

.card-content {
  padding: 2rem;
}

.top-section {
  margin-bottom: 2rem;
}

.custom-tabs {
  display: flex;
  margin-top: 1rem;
}

.tab {
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-weight: 500;
  color: #4a4a4a;
  transition: all 0.3s ease;
}

.tab.is-active {
  color: #3273dc;
  font-size: 1.5em;
  font-weight: 700;
}

.bottom-section {
  background-color: transparent;
}

.tab-content {
  padding: 0; /* Remove padding here */
}

/* New styles for the gradient effect */
.tw-bg-gradient-to-b {
  background-image: linear-gradient(to bottom, 
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9) 20%,
    rgba(255, 255, 255, 0.7) 40%,
    rgba(255, 255, 255, 0.4) 60%,
    rgba(255, 255, 255, 0.1) 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.frosted-header {
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.frosted-content {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

/* For webkit browsers */
@supports (-webkit-backdrop-filter: none) {
  .frosted-header,
  .frosted-content {
    -webkit-backdrop-filter: blur(10px);
  }
}

/* For Firefox */
@supports (backdrop-filter: none) {
  .frosted-header,
  .frosted-content {
    backdrop-filter: blur(10px);
  }
}

.action-button-container {
  position: relative;
  padding: 3px;
  background: linear-gradient(to right, #ec4899, #8b5cf6);
  border-radius: 9999px;
}

.action-button {
  background: white;
  border-radius: 9999px;
}

.action-button:hover {
  transform: translateY(-1px);
}
