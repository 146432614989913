
.pdf-viewer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 4px;
  overflow: hidden;
}

.pdf-display {
  flex: 1;
  position: relative;
  overflow: auto;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}

canvas {
  position: absolute;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  height: auto;
}

.form-canvas {
  z-index: 3;
}

.highlight-canvas {
  z-index: 2;
}

.pdf-canvas {
  z-index: 1;
}

.pdf-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
