
/* NOT SCOPED !! */
.quick-report.box {
  box-shadow: inset 0 0 10px grey;
}

.quick-report-preview-modal {
  .modal-content {
    overflow: hidden;
    max-width: none !important;
  }

  iframe {
    transform: scale(0.75) translateX(-50%);
    transform-origin: 0 0;
    position: relative;
    right: -50%;
  }
}
