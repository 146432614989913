
.property-field {
  display: flex;
  align-items: center;
  gap: 1em;
}

.property-side {
  flex-shrink: 0;
  gap: 0.25rem;
  width: 16em;
}

.property-title {
  font-weight: 600;
  line-height: 1;
  color: var(--stk-text-strong);
}

.property-description {
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1;
  color: var(--stk-text-normal);
}

.property-main {
  flex-grow: 1;
}
