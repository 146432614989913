
/* Remove the Oruga dropdown styles */
/* Add new merge view styles */
.merge-view {
  padding: 1rem;
  background: var(--stk-text-15);
  border-top: 1px solid var(--stk-border);
}

.merge-candidates {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

.merge-candidate {
  background: var(--stk-text-20);
  border: 1px solid var(--stk-border);
  border-radius: var(--stk-border-radius);
  transition: all 0.2s ease;
  cursor: pointer;
  overflow: hidden;
}

.merge-candidate:hover {
  border-color: var(--stk-primary);
  background: var(--stk-text-25);
  min-width: 300px;
}

.candidate-preview {
  padding: 1rem;
}

.candidate-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
}

.candidate-name {
  font-weight: 600;
  color: var(--stk-text-strong);
  font-size: 1rem;
  flex: 1;
  min-width: 0;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.4;
}

.candidate-sources {
  background: var(--stk-text-15);
  border-radius: var(--stk-border-radius);
  padding: 0.5rem;
}

.source-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background: var(--stk-text-25);
  border-radius: var(--stk-border-radius);
  margin-bottom: 0.5rem;
  gap: 1rem;
  border: 1px solid var(--stk-border);
}

.source-preview:last-child {
  margin-bottom: 0;
}

.source-preview .source-name {
  color: var(--stk-text-inverse);
  font-weight: 600;
  font-size: 0.9rem;
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.source-preview .source-date {
  color: var(--stk-text-inverse);
  opacity: 0.8;
  font-size: 0.875rem;
  flex-shrink: 0;
}

.merge-review {
  padding: 1rem;
  background: var(--stk-text-15);
  border-top: 1px solid var(--stk-border);
}

.merge-entities-info {
  background: var(--stk-text-20);
  padding: 1rem;
  border-radius: var(--stk-border-radius);
  margin-bottom: 1rem;
}

.merge-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

.merge-field {
  background: var(--stk-text-20);
  border: 1px solid var(--stk-border);
  border-radius: var(--stk-border-radius);
  padding: 1rem;
}

.merge-values {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.merge-value-option {
  padding: 0.75rem;
  background: var(--stk-text-15);
  border: 1px solid var(--stk-border);
  border-radius: var(--stk-border-radius);
  cursor: pointer;
  transition: all 0.2s ease;
}

.merge-value-option:hover {
  border-color: var(--stk-primary);
  background: var(--stk-primary-10);
}

.merge-value-option.selected {
  border-color: var(--stk-primary);
  background: var(--stk-primary-20);
}

.merge-actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--stk-border);
}

/* Add modal-specific styles */
.merge-modal {
  max-width: 800px;
  width: 90%;
}

.merge-modal :deep(.modal-card) {
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
}

.merge-modal :deep(.modal-card-body) {
  max-height: 70vh;
  overflow-y: auto;
  padding: 1.5rem;
}

.merge-modal :deep(.modal-card-foot) {
  padding: 1rem 1.5rem;
  background: var(--stk-text-15);
  border-top: 1px solid var(--stk-border);
}

.merge-modal :deep(.modal-card-head) {
  padding: 1rem 1.5rem;
  background: var(--stk-text-15);
  border-bottom: 1px solid var(--stk-border);
}

.merge-modal :deep(.modal-card-title) {
  color: var(--stk-text-strong);
  font-weight: 600;
  font-size: 1.25rem;
}
