
.stk-button {
  --stk-icon-dimensions: 1.25em;

  cursor: pointer;
  font-weight: var(--stk-button-font-weight);
  justify-content: center;
  gap: 0.5em;
  padding-left: calc(var(--stk-button-padding-x) - var(--stk-control-border-width));
  padding-right: calc(var(--stk-button-padding-x) - var(--stk-control-border-width));

  .stk-icon {
    color: var(--stk-primary);

    &:first-child {
      margin-left: -0.25em;
    }

    &:last-child {
      margin-right: -0.25em;
    }
  }

  &:hover {
    background-color: var(--stk-control-hover-background-color);
  }

  &:active {
    background-color: var(--stk-control-active-background-color);
    box-shadow: none;
  }

  &:focus-visible {
    border-color: var(--stk-control-focus-border-color);
    outline-color: var(--stk-control-focus-outline-color);
    outline-style: var(--stk-control-focus-outline-style);
    outline-width: var(--stk-control-focus-outline-width);
  }

  &[disabled] {
    box-shadow: none;
    opacity: 0.5;
    pointer-events: none;
  }

  &.is-fullwidth {
    text-align: center;
    justify-content: center;
    width: 100%;
    flex-grow: 1;
  }

  &.is-primary {
    --stk-control-background-color: var(--stk-primary-55);
    --stk-control-hover-background-color: var(--stk-primary-50);
    --stk-control-active-background-color: var(--stk-primary-45);
    --stk-control-border-color: var(--stk-primary-30);
    --stk-control-hover-border-color: var(--stk-primary-25);
    --stk-control-active-border-color: var(--stk-primary-20);
    --stk-control-color: var(--stk-primary-100);
    --stk-control-hover-color: var(--stk-primary-100);
    --stk-control-active-color: var(--stk-primary-100);

    .stk-icon {
      color: inherit;
    }
  }

  &.is-danger {
    --stk-control-background-color: var(--stk-danger-55);
    --stk-control-hover-background-color: var(--stk-danger-50);
    --stk-control-active-background-color: var(--stk-danger-45);
    --stk-control-border-color: var(--stk-danger-30);
    --stk-control-hover-border-color: var(--stk-danger-25);
    --stk-control-active-border-color: var(--stk-danger-20);
    --stk-control-color: var(--stk-danger-100);
    --stk-control-hover-color: var(--stk-danger-100);
    --stk-control-active-color: var(--stk-danger-100);

    .stk-icon {
      color: inherit;
    }
  }

  &.is-ghost {
    --stk-control-border-width: 0px;
    background: none;
    box-shadow: none;
    color: inherit;
  }

  &.is-loose {
    --stk-control-padding-y: 0.875em;
  }

  &.is-tight {
    --stk-button-padding-x: 0.75em;
    --stk-control-padding-y: 0.5em;
  }

  &.is-icon-only {
    --stk-button-padding-x: var(--stk-control-padding-y);

    .stk-icon {
      margin: 0 !important;
    }
  }

  &.is-loading {
    color: transparent;
    text-shadow: none;
  }

  /* Same size as the Badge component */
  &.is-badge {
    font-size: 0.875rem;
    line-height: 1rem;
    padding: 0.25em 0.5em;
    border-radius: 0.5em;
    gap: 0.5em;

    .stk-icon {
      font-size: 0.75rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

[data-stk-theme="dark"] {
  .stk-button {
    &.is-primary {
      --stk-control-border-color: var(--stk-primary-60);
      --stk-control-hover-border-color: var(--stk-primary-55);
      --stk-control-active-border-color: var(--stk-primary-50);
    }
  }
}

.stk-button-icon-before:first-child {
  margin-left: -0.25em;
}

.stk-button-icon-after:last-child {
  margin-right: -0.25em;
}

.stk-button-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(1em * var(--stk-control-line-height));
  flex-shrink: 0;
  width: calc(1em * var(--stk-control-line-height));
}

.stk-button-loading {
  --stk-loader-color: var( --stk-control-color);
  position: absolute;
  left: calc(50% - 0.5em);
  top: calc(50% - 0.5em);
}
