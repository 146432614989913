
/* Add your styles here */
body {
  padding: 0.5em;
}

.rainbow {
  background: linear-gradient(
    to right,
    #ef5350,
    #f48fb1,
    #7e57c2,
    #2196f3,
    #26c6da,
    #43a047,
    #eeff41,
    #f9a825,
    #ff5722
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h1 {
  margin-top: 0;
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 6rem;
}
.input {
  font-family: "DM Sans";
  font-weight: 700;
}

.verticalflex {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.madlibsflex {
  display: flex;
  height: 70vh;
  justify-content: center;
  align-items: center;
}

.madlibscontainer {
  max-width: 55rem;
}
