
.file-cta, .file-name {
  border: 1px solid #dbdbdb;
}

.file-name {
  border-left: none;
  max-width: none;
}

.modal-card {
  max-width: 480px;
}
