
.stk-dropdown {
  display: inline-flex;
  vertical-align: top;
  position: relative;

  &.is-fullwidth {
    &,
    .stk-dropdown-trigger {
      display: flex;
      width: 100%;
    }
  }
}

.stk-dropdown-trigger {
  display: inline-flex;
  vertical-align: top;
}

.stk-dropdown-box {
  background-color: var(--stk-control-background-color);
  border-color: var(--stk-control-border-color);
  border-radius: 1em;
  border-style: solid;
  border-width: var(--stk-control-border-width);
  box-shadow: var(--stk-control-shadow);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 0.5rem);
  opacity: 0;
  padding: 0.5rem;
  pointer-events: none;
  transform: translateY(-0.25rem);
  transition-duration: var(--stk-transition-duration);
  transition-property: opacity, transform;
  width: 16rem;
  z-index: 10;
}

.stk-dropdown {
  &.is-anchored-left {
    .stk-dropdown-box {
      left: 0;
    }
  }

  &.is-anchored-right {
    .stk-dropdown-box {
      right: 0;
    }
  }

  &.is-open {
    .stk-dropdown-box {
      opacity: 1;
      pointer-events: auto;
      transform: none;
    }
  }
}
