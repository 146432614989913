
.button-base {
  @apply tw-text-base;
  @apply tw-px-4 tw-py-2 tw-rounded-md tw-border-none;
  @apply tw-flex tw-gap-2 tw-items-center tw-justify-center;
}

.input-base {
  @apply tw-text-base;
  @apply tw-px-4 tw-py-2 tw-rounded-md;
  @apply tw-border tw-border-gray-300 tw-border-solid;
  @apply tw-bg-gray-50;
}
