
.tw-indeterminate-progress-bar {
  @apply tw-absolute tw-top-0 tw-left-0 tw-bottom-0 tw-bg-gray-500;
  width: 30%;
  animation: indeterminate 1.5s infinite linear;
}

@keyframes indeterminate {
  0% {
    left: -30%;
  }
  100% {
    left: 100%;
  }
}
