
.stk-toggle {
  align-items: center;
  display: inline-flex;
  gap: 0.5em;
  vertical-align: top;
}

.stk-toggle-label {
  background-color: var(--stk-border);
  border-radius: 9999px;
  cursor: pointer;
  display: inline-flex;
  padding: 0.25em;
  position: relative;
  width: 2.5em;
}

.stk-toggle-input {
  opacity: 0;
  height: 0;
  width: 0;
}

.stk-toggle-slider {
  background-color: var(--stk-text-weak);
  border-radius: 9999px;
  cursor: pointer;
  display: flex;
  height: 1em;
  transition-duration: var(--stk-transition-duration);
  transition-property: background-color, transform;
  width: 1em;
}

/* Loading state */
.stk-toggle.is-loading .stk-toggle-slider {
  background-color: transparent !important;
}

/* Checked state */
.stk-toggle.is-checked .stk-toggle-slider,
.stk-toggle-input:checked + .stk-toggle-slider {
  background-color: var(--stk-primary);
  transform: translateX(1em);
}
