
.grid-container {
  padding: 1rem;
}
button {
  margin-top: 1rem;
}
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
}
::v-deep .o-table tr:last-child {
  background-color: #f5f5f5;
  border-top: 2px solid #dbdbdb;
}
