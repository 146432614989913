
.pickerTrigger {
  cursor: pointer;
}
.portfolio-detail-card {
  .title {
    margin-bottom: 0.75em;
  }
  .dndx-show {
    margin-top: 1em;
  }
}
.delete-prospect p {
  font-size: .8em;
}
.card {
  margin-bottom: 1.5rem;
}
.bottomcard {
  margin-top: -1.25rem;
  background-color: #fcfcfc;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

}
.tags:last-child {
  margin-bottom: 0rem;
}
.nomarginbottom {
  margin-bottom: -.5em;
}
