
.round {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.25);
}

button.round.selected {
  border: 4px solid #000;
}
