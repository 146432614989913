
.advisor-core-form-upload {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--stk-text-10);
  color: var(--stk-text-strong);
}

.form-upload-container {
  flex: 1;
  display: flex;
  gap: 1.5rem;
  padding: 1.5rem;
  overflow: hidden;
  background-color: var(--stk-text-10);
}

.form-steps-sidebar {
  width: 400px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow-y: auto;
}

.form-step {
  background: var(--stk-text-20);
  border-radius: var(--stk-border-radius);
  padding: 1.5rem;
  border: 1px solid var(--stk-border);

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}

.step-title {
  margin-bottom: 1.25rem;
  color: var(--stk-text-strong);

  .step-indicator {
    color: var(--stk-primary);
    font-weight: 600;
  }
}

.info-message {
  color: var(--stk-text-weak);
  font-size: 0.875rem;
  padding: 0.75rem;
  background: var(--stk-text-15);
  border-radius: var(--stk-border-radius);
  border: 1px solid var(--stk-border);

  i {
    margin-right: 0.5rem;
  }
}

.radio-group {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: var(--stk-text-strong);

  input[type="radio"] {
    margin: 0;
    accent-color: var(--stk-primary);
  }
}

.form-select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--stk-border);
  border-radius: var(--stk-border-radius);
  background: var(--stk-text-15);
  color: var(--stk-text-strong);

  &:focus {
    border-color: var(--stk-primary);
    outline: none;
  }

  option {
    background: var(--stk-text-15);
    color: var(--stk-text-strong);
  }
}

.help-text {
  font-size: 0.875rem;
  color: var(--stk-text-weak);
  margin-top: 0.5rem;
}

.processing-section {
  .processing-info {
    font-size: 0.875rem;
    margin-bottom: 1rem;
    color: var(--stk-text-strong);

    ul {
      margin: 0.75rem 0 0 1.25rem;

      li {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.download-section {
  .download-info {
    font-size: 0.875rem;
    margin-bottom: 1rem;
    color: var(--stk-text-strong);
  }
}

.pdf-viewer-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--stk-text-20);
  border-radius: var(--stk-border-radius);
  overflow: hidden;
  min-width: 0;
  border: 1px solid var(--stk-border);
}

.pdf-controls {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--stk-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--stk-text-15);
}

.page-navigation {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.page-input {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  input {
    width: 60px;
    text-align: center;
    padding: 0.25rem;
    border: 1px solid var(--stk-border);
    border-radius: var(--stk-border-radius);
    background: var(--stk-text-15);
    color: var(--stk-text-strong);

    &:focus {
      border-color: var(--stk-primary);
      outline: none;
    }
  }

  .page-count {
    color: var(--stk-text-weak);
  }
}

.pdf-view-container {
  flex: 1;
  position: relative;
  overflow: auto;
  background: var(--stk-text-10);
}

.resize-handle {
  width: 4px;
  background: var(--stk-border);
  cursor: col-resize;
  transition: background-color 0.2s;
  flex-shrink: 0;

  &:hover {
    background: var(--stk-primary-30);
  }
}

.section-legend-panel {
  width: 280px;
  flex-shrink: 0;
  background: var(--stk-text-15);
  border-left: 1px solid var(--stk-border);
  border-radius: 0 var(--stk-border-radius) var(--stk-border-radius) 0;
}

// Modal styles
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.85);
  align-items: center;
  justify-content: center;

  &.is-active {
    display: flex;
  }
}

.modal-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal-card {
  position: relative;
  background: var(--stk-text-20);
  border-radius: var(--stk-border-radius);
  max-width: 480px;
  width: 100%;
  margin: 1rem;
  border: 1px solid var(--stk-border);
}

.modal-card-head {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--stk-border);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--stk-text-20);
}

.modal-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--stk-text-strong);
}

.delete {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  color: var(--stk-text-weak);

  &:hover {
    color: var(--stk-text-strong);
  }
}

.modal-card-body {
  padding: 1.5rem;
  background: var(--stk-text-20);
}

.entity-list {
  .entity-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
    border-radius: var(--stk-border-radius);
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: var(--stk-primary-30);
    }

    .entity-info {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      .entity-name {
        font-weight: 500;
        color: var(--stk-text-strong);
      }

      .entity-type {
        font-size: 0.875rem;
        color: var(--stk-text-weak);
      }
    }

    .icon {
      color: var(--stk-text-weak);
    }
  }
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.checkbox-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  color: var(--stk-text-strong);

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  input[type="checkbox"] {
    margin: 0;
    accent-color: var(--stk-primary);

    &:disabled {
      cursor: not-allowed;
    }
  }

  .unavailable-text {
    color: var(--stk-text-weak);
    font-size: 0.875rem;
  }
}

@media (max-width: 1024px) {
  .form-upload-container {
    flex-direction: column;
  }

  .form-steps-sidebar {
    width: 100%;
  }

  .pdf-viewer-section {
    height: 600px;
  }

  .section-legend-panel {
    width: 100% !important;
    border-left: none;
    border-top: 1px solid var(--stk-border);
    border-radius: 0 0 var(--stk-border-radius) var(--stk-border-radius);
  }

  .resize-handle {
    display: none;
  }
}

@media (max-width: 768px) {
  .form-upload-container {
    padding: 1rem;
  }

  .pdf-viewer-section {
    height: 500px;
  }

  .section-legend-panel {
    width: 100% !important;
    border-left: none;
    border-top: 1px solid var(--stk-border);
  }

  .resize-handle {
    display: none;
  }
}
