
.square {
  width: inherit;
  height: inherit;
  background: green;
  border-radius: 9px;
}
.myflex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.flexitem {
  width: 47%;
  margin-bottom: 2%;
}
.notification .progress-wrapper {
  position: relative;
}
