
.save-button-container {
  background-color: hsl(0deg 0% 70% / 70%);
  position: sticky;
  border-radius: 1rem;
  bottom: 1rem;
  margin: 0 1rem;
  padding: 1rem;
  display: flex;
  backdrop-filter: blur(4px);
}

.save-button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 1em;
  flex-grow: 1;
}

.save-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
