
.page {
  max-width: 70em;
  margin: auto;
  margin-top: 3em;
  background-color: white;
  border: 1px solid grey;
  padding: 3em;
}
.textcolumns {
  column-count: 2;
  column-width: 20em;
}
