:root {
  --oruga-variant-primary: #8c67ef;
  --oruga-menu-item-active-background-color: #1b1e2a;
  /* --oruga-tabs-link-active-color: #8c67ef; */
  --oruga-tabs-toggle-link-active-background-color: none; 
  --oruga-tabs-toggle-link-active-border-color: #8c67ef; 
  --oruga-tabs-toggle-link-active-color: #8c67ef; 
  --oruga-tabs-toggle-link-active-color: #8c67ef; 
  --oruga-tabs-link-active-color: #8c67ef; 
  --oruga-tabs-link-active-border-bottom-color: #8c67ef; 
  --oruga-tabs-link-color: #adadad;
  --oruga-tabs-boxed-link-hover-background-color: rgba(255, 255, 255, 0.2);
  --oruga-dropdown-item-active-background-color: #8c67ef;
}

.o-tabs__nav div {
  margin-right: 0.5em;
}

.--oruga-tabs-margin-bottom {
  margin-bottom: 0;
}

.o-tabs__content {
  margin: 0px;
  padding: 0px;
}
#analysisTabs > nav {
  flex-wrap: wrap;
}