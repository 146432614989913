
.notification.is-success {
  background-color: #d4edda; /* Light green background */
  border: 1px solid #c3e6cb; /* Green border */
  color: #155724; /* Dark green text */
  padding: 16px; /* Padding inside the box */
  border-radius: 8px; /* Rounded corners */
}
.notification.is-success .fa-check {
  color: #155724; /* Dark green color for the checkmark icon */
}
