
#design-portfolio-suggestions-header {
  display:     flex;
  align-items: center;
  width:       100%;
}

.title, .buttons {
  flex-basis: 10%;
  flex-grow:  1;
}

.buttons {
  justify-content: flex-end;
}

.note {
  max-width: 30rem;
}
