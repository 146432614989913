
  .scaled-frame {
    /* zoom: 0.75; */
    -moz-transform: scale(0.50);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.50);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.50);
    -webkit-transform-origin: 0 0;
  }
  .threeQuarters {
    -moz-transform: scale(0.75);
  }
  .mybackground {
    background-color: #f7f7f3;
  }

    p, h2, button, h4 {
      font-family: "DM Sans" !important;
    }
    h1, h3 {
      font-family: "DM Serif Display";
    }
    hr {
      background-color: rgb(49, 47, 48); /* black 95 */
    }
    .displayfont {
      font-family: "DM Serif Display";
    }
.desc-mifid {
  font-weight: normal;
  letter-spacing: -.01em;
  font-size: 1.25rem;
}
