
.component {
  display: flex;
  align-items: end;
  gap: 1em;
  flex-grow: 1;
  justify-content: end;
  flex-direction: column;
}

.badge {
  margin-block-end: auto;
}
