
.has-text-weight-extra-bold {
  font-weight: 900 !important;
}

.task-editor-modal ::v-deep .modal-content {
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.task-editor-modal ::v-deep .field-label {
  font-weight: 600;
  color: #4a5568;
}

.task-editor-modal ::v-deep .input,
.task-editor-modal ::v-deep .select select {
  border-color: #e2e8f0;
  border-radius: 0.5rem;
  transition: border-color 0.2s ease;
}

.task-editor-modal ::v-deep .input:focus,
.task-editor-modal ::v-deep .select select:focus {
  border-color: #4299e1;
  box-shadow: 0 0 0 1px #4299e1;
}

.task-editor-modal ::v-deep .button {
  font-weight: 600;
  transition: all 0.2s ease;
}

.custom-tabs-container {
  display: inline-flex;
  background-color: #f3f4f6;
  padding: 0.25rem;
  border-radius: 9999px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 20rem;
  column-gap: var(--stk-size-8)
}

#header {
  grid-row: 1;
  grid-column: 1/3;
}

#type {
  grid-row: 2;
  grid-column: 1 / span 2;
  display: flex;
  align-items: center;
  gap: 1em;

  :deep(.stk-callout-body) {
    display: flex;
    align-items: center;
    gap: 1em;
  }
}

#main-content {
  grid-row: 3;
  grid-column: 1;
}

#sidebar {
  grid-row: 3;
  grid-column: 2;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .sidebar {
    margin-top: 1rem;
  }
}

.button.o-btn--outlined {
  background-color: white;
  color: #363636;
  border-color: #dbdbdb;
}

.button.o-btn--outlined:hover:not(:disabled) {
  background-color: #f5f5f5;
  border-color: #b5b5b5;
}
.has-text-weight-extra-bold {
  font-weight: 900 !important;
}

.task-editor-modal ::v-deep .modal-content {
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.task-editor-modal ::v-deep .field-label {
  font-weight: 600;
  color: #4a5568;
}

.task-editor-modal ::v-deep .input,
.task-editor-modal ::v-deep .select select {
  border-color: #e2e8f0;
  border-radius: 0.5rem;
  transition: border-color 0.2s ease;
}

.task-editor-modal ::v-deep .input:focus,
.task-editor-modal ::v-deep .select select:focus {
  border-color: #4299e1;
  box-shadow: 0 0 0 1px #4299e1;
}

.task-editor-modal ::v-deep .button {
  font-weight: 600;
  transition: all 0.2s ease;
}

.tab-label {
  cursor: inherit;
}

/* Style for disabled tabs */
:deep(.o-tabs-item.is-disabled) {
  opacity: 0.6;
  cursor: not-allowed;
}

:deep(.o-tabs-item.is-disabled:hover) {
  cursor: not-allowed;
}

.textarea {
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  padding: 0.75rem;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  transition: border-color 0.2s ease;
}

.textarea:focus {
  border-color: #485fc7;
  box-shadow: 0 0 0 0.125em rgba(72, 95, 199, 0.25);
}

.attendees-section {
  background-color: #f5f5f5;
  border-radius: 4px;
}

.tag .button.is-ghost {
  padding: 0;
  height: auto;
}

.tag .button.is-ghost:hover {
  background: none;
  opacity: 0.7;
}
.dropdown-item {
  text-align: left !important;
}
