
.workflow-card {
  .step-link {
    text-align: center;
    a {
      text-decoration: none;
    }
  }
}
.parent-firm-logo {
  width: 250px;
  margin-bottom: 1rem;
}
.expanse {
  position: inherit;
  height: 100%;
  width: 100%;
  background-color: #FCFCFC;
}
