
.meeting-list {
  @apply tw-flex tw-flex-col tw-justify-end tw-gap-2 tw-w-96;
}

.meeting-list ul {
  @apply tw-flex tw-flex-col tw-gap-2;
  @apply tw-overflow-y-auto tw-h-80 tw-p-3;
  @apply tw-shadow-md tw-rounded-lg tw-p-3 tw-bg-slate-50;
}
