
.component {
  background-color: var(--stk-scheme-main);
}

.modal-content {
  background-color: #fff;
  width: 90%;
  max-width: 60rem;
  max-height: 90vh;
  border-radius: 1em;
  overflow: auto;
}
