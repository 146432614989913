
/* Add your styles here */
body {
    padding: 0.5em;
}
.portfolio-title {
    text-align: left;
    font-weight: bold;
    font-size: 2em; /* Change the value as needed */
  }
.portfolio-amount {
    text-align: right;
    font-weight: bold;
    font-size: 2em; /* Change the value as needed */
  }
.section {
    background-color: inherit;
}

.uploadcontainer {
    max-width: 65rem;
    margin: auto;
}

.suggestion-box {
    background-color: white;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    margin-top: 5px;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    z-index: 1000;
  }
  
  .suggestion-box ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .suggestion-box li {
    cursor: pointer;
    padding: 8px 16px;
  }
  
  .suggestion-box li:hover {
    background-color: #f5f5f5;
  }
  .search-input {
    width: 100%;
  }
  .dollar-input {
    width: 60%;
  }
  .amount {
    margin-top: 20px;
  }
