

.mygrid {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;

  .box {
    width: 150px;
    height: 150px;
    margin: 1rem;
  }
}

div > a > figure > img {
  margin: auto;
}



