
p.heading {
  font-size: 1.2rem;
  font-weight: bold;
}

/* Hide elements in exported PDF */
@media print {
  .no-print {
    display: none !important;
  }
}

/* Area container styling */
.area-container {
  display: block;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

/* Special styling for PDF export mode */
.for-pdf-export .area-container {
  margin-bottom: 0;
}

/* Temporary container for PDF generation */
.area-pdf-container {
  background: white;
  padding: 20px;
  width: 612px; /* Letter width */
  box-sizing: border-box;
  font-family: inherit;
  font-size: 9pt; /* Reduced font size for PDF output */
}

/* Control font sizes in PDF more explicitly */
.area-pdf-container .heading {
  font-size: 12pt;
}

.area-pdf-container .tw-text-2xl {
  font-size: 14pt; 
}

.area-pdf-container .has-text-weight-bold,
.area-pdf-container strong {
  font-size: 9pt;
}

/* Improved header section with better spacing */
.header-section {
  font-size: 12pt;
  font-weight: bold;
  margin-bottom: 1.5rem !important;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
}

/* Added padding for exported elements */
.for-pdf-export {
  padding: 15px;
}

/* Loading overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.loading-content i {
  color: #48c774; /* Green color to match the PDF button */
  margin-bottom: 10px;
}
