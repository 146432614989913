
.round {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.25);
}
input[type=color] {
  height: 1.5rem;
  margin-left: 0.5rem;
  position: relative;
  top: -4px;
}
button.round.selected {
  border: 4px solid #000;
}
