
.message.is-primary {
  background-color: rgba(255, 255, 255, 0.897);
}

.message.is-primary .message-body {
  color: rgba(64, 64, 64, 0.7);
}

.max-width {
  max-width: 500px;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.spinning {
  width: 100px;
  animation: rotation 2s infinite linear;
}

.myImageSize {
  width: 96px;
  height: 48px;
}