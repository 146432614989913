
#design-portfolio-suggestions .b-table {
  font-size: 1.25rem;
}

#design-portfolio-suggestions tr.highlighted td:nth-last-child(-n + 3) {
  font-weight: bold;
  color: #48c78e;
}

#design-portfolio-suggestions a {
  text-decoration: underline;
  color: #363636;
}

#design-portfolio-suggestions tr.highlighted a {
  color: #48c78e;
}
