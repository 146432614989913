
.meetings-table {
  margin-bottom: 0 !important;

  td {
    vertical-align: middle;
  }
}

.attendees-cell {
  width: 16em;
}

.status-cell {
  width: 8em;
}

.bot-cell {
  width: 7em;
  white-space: nowrap;
}

.date-cell {
  width: 13em;
  white-space: nowrap;
}

.prep-cell {
  width: 14em;
  white-space: nowrap;
}

.actions-cell {
  width: 10em;
  white-space: nowrap;
}

.actions-buttons {
  flex-wrap: nowrap;
}

.empty {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.empty-callout {
  justify-content: center;
  max-width: 24em;
  padding: 2em 3em;
}

.contact-cell {
  width: 14em;
}
