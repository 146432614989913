
.input-row {
  display: flex;
  gap:     2rem;
}

.input-row > * {
  flex-basis: 50%;
  flex-grow:  1;
}
