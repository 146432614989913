
.howto {
  background-color: var(--stk-success-90);
  color: var(--stk-success-30);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1em;
  padding: 1em 1.25em;

  strong {
    color: inherit;
    font-weight: 600;
  }
}

.howto-button {
  flex-shrink: 0;
}

.modal.is-active {
  z-index: 1000;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.86);
}
