
.site-nav-full {
  z-index: 10000;
  position: fixed;
  top: 0; left: 0; bottom: 0;
  transition: left 0.5s ease;

  padding: 1em;
  background-color: #f2f2f2;
  border-right: 1px solid #f5f5f5;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.2);

  font-size: 16px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.toggle {
  position: absolute;
  top: 12px;
  right: -26px;
  transition: all 0.5s ease;

  font-size: 28px;
  color: #3a66ac;
  opacity: 0.5;
  cursor: pointer;
}

.toggle:hover {
  opacity: 1;
}

.site-nav-full.open .toggle {
  right: 8px;
  transform: rotateY(180deg);
}

.header-icons {
  display: flex;
  font-size: 18px;
}

.header-icons i:hover {
  cursor: pointer;
}

.header-icons a {
  color: gray;
}

.content, .menu {
  display: flex;
  flex-direction: column;
}

.content {
  gap: 2em;
}

.menu {
  gap: 0.5em;
}

.client-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25em;
}
