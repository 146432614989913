
.global-loading-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .loading-message {
    color: white;
    margin-top: 1rem;
    font-size: 1rem;
  }
}
