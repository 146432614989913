
.stk-dropzone {
  --color: var(--stk-primary);
  --color-h: var(--stk-primary-h);
  --color-s: var(--stk-primary-s);
  background-color: hsl(var(--color-h) var(--color-s) 98%);
  border: 1px dashed var(--color);
  display: flex;
  color: var(--color);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 1.5em;
  border-radius: 1em;
  position: relative;
  width: 100%;
  min-height: 9rem;
  transition-duration: var(--stk-transition-duration);
  transition-property: background-color, border-color, color;

  &:hover {
    background-color: hsl(var(--color-h) var(--color-s) 95%);

    .stk-button {
      background-color: var(--stk-control-hover-background-color);
    }
  }

  &.is-dragover {
    background-color: hsl(var(--color-h) var(--color-s) 95%);
    border-color: hsl(var(--color-h) var(--color-s) 50%);
  }

  &.is-dragover {
    .stk-dropzone-body {
      opacity: 0;
      transform: translateY(-0.5em);
    }

    .stk-dropzone-hover {
      opacity: 1;
      transform: none;
    }
  }

  &.has-progress {
    pointer-events: none;

    .stk-dropzone-body {
      opacity: 0;
      transform: translateY(-0.5em);
    }

    .stk-dropzone-progress {
      opacity: 1;
      transform: none;
    }
  }

  &.progress-done {
    --color: var(--stk-success-50);
    --color-h: var(--stk-success-h);
    --color-s: var(--stk-success-s);
    pointer-events: none;
  }

  &.show-list {
    min-height: 12rem;
  }
}

.stk-dropzone-body {
  align-items: center;
  flex-grow: 1;
  transition-duration: var(--stk-transition-duration);
  transition-property: opacity, transform;
  pointer-events: none;
}

.stk-dropzone-icon {
  color: inherit;
}

.stk-dropzone-files {
  justify-content: center;
}

.stk-dropzone-progress,
.stk-dropzone-hover {
  height: 4em;
  display: flex;
  align-items: center;
  width: 20em;
  opacity: 0;
  text-align: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 10em);
  top: calc(50% - 2em);
  transform: translateY(-0.5em);
  transition-duration: var(--stk-transition-duration);
  transition-property: opacity, transform;
  pointer-events: none;
}

.stk-dropzone-bar {
  width: 20em;
  height: 0.5em;
  flex-shrink: 0;
  border-radius: 9999px;
  background-color: #fff;
  display: flex;
  margin-bottom: 0.5em;
  overflow: hidden;
}

.stk-dropzone-bar-load {
  background-color: var(--color);
  width: 100%;
  transform-origin: left center;
  transform: scaleX(calc(var(--progress) / 100));
  transition-duration: var(--stk-transition-duration);
  transition-property: transform;
}
