
.expand-y {
  position: relative;
  overflow: hidden;
  transition: height 0.5s ease;
  width: 100%;
  /* border: 2px dashed black; */
}
.content {
  position: absolute;
  width: 100%;
}
