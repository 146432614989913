
.stk-label {
  color: var(--stk-text-weak);
  font-size: var(--stk-size-3_5);
  font-weight: 500;
  line-height: 1.25;

  label {
    font-weight: 500;
  }
}
