
.majestic-gradient {
  background: linear-gradient(135deg, #6F5CC2 0%, #D5A9E8 100%);
  min-height: 100vh;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.box {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.title, .subtitle {
  color: #4a4a4a;
}

.label {
  margin-top: 10px;
  font-weight: bold;
}

.input, .file-input {
  border-radius: 5px;
  border: 1px solid #d5d5d5;
}

.checkbox {
  margin-top: 10px;
}

.report-section {
  padding: 20px;
}

h3.title, h4.title {
  margin-bottom: 15px;
  color: #333;
}

h5.title {
  margin-bottom: 10px;
  color: #555;
}

p {
  margin-bottom: 10px;
  line-height: 1.6;
}

.canvas-container {
  max-width: 100%;
  max-height: 400px;
  margin-bottom: 20px;
}

.insights-section .insight {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.tour-step {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.tour-step img {
  max-width: 100%;
  height: auto;
}

.tour-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

#docmaster {
  display: grid;
  grid-template-columns: 3fr 1fr;
  max-width: 70em;
  margin: auto;
  gap: 2px;
  background-color: #333;
} 
#docmaster > * {
  background-color: white;
}

