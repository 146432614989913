
.note-taking-component {
  font-family: 'Inter', sans-serif;
}
.has-text-weight-extra-bold {
  font-weight: 900 !important;
}

.task-editor-modal ::v-deep .modal-content {
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.task-editor-modal ::v-deep .field-label {
  font-weight: 600;
  color: #4a5568;
}

.task-editor-modal ::v-deep .input,
.task-editor-modal ::v-deep .select select {
  border-color: #e2e8f0;
  border-radius: 0.5rem;
  transition: border-color 0.2s ease;
}

.task-editor-modal ::v-deep .input:focus,
.task-editor-modal ::v-deep .select select:focus {
  border-color: #4299e1;
  box-shadow: 0 0 0 1px #4299e1;
}

.task-editor-modal ::v-deep .button {
  font-weight: 600;
  transition: all 0.2s ease;
}

.custom-tabs-container {
  display: inline-flex;
  background-color: #f3f4f6; /* Light gray background */
  padding: 0.25rem;
  border-radius: 9999px;
}

.custom-tabs :deep(.o-tabs-wrapper) {
  background-color: transparent;
}

.custom-tabs :deep(.o-tabs-item) {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  border-radius: 9999px;
  transition: all 0.3s ease-in-out;
  padding: 0.25rem 0.75rem;
}

.custom-tabs :deep(.o-tabs-item.is-active) {
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.custom-tabs :deep(.o-tabs-item .tab-label) {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280; /* Medium gray for inactive tabs */
}

.custom-tabs :deep(.o-tabs-item.is-active .tab-label) {
  color: #2563eb; /* Blue color for active tab text */
}


.grid-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 12em 10em 1fr;
}

.header {
  grid-column: 1/3;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}
.metadata-row {
  grid-column: 1/3;
  grid-row: 2;
}
.main-content {
  background-color: #f9fafb;
  padding: 1rem;
  border-radius: 0.5rem;
  grid-column: 1;
  grid-row: 3;
  border-top: 1px solid #D0D4DC;
  border-right: 1px solid #D0D4DC;
  border-radius: 0%;
}

.sidebar {
  grid-row: 3;
  grid-column: 2;
  background-color: #ffffff;
  padding: 1rem;
  border-top: 1px solid #D0D4DC;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }

  .sidebar {
    margin-top: 1rem;
  }
}


.has-text-weight-extra-bold {
  font-weight: 900 !important;
}

.task-editor-modal ::v-deep .modal-content {
  border-radius: 1rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.task-editor-modal ::v-deep .field-label {
  font-weight: 600;
  color: #4a5568;
}

.task-editor-modal ::v-deep .input,
.task-editor-modal ::v-deep .select select {
  border-color: #e2e8f0;
  border-radius: 0.5rem;
  transition: border-color 0.2s ease;
}

.task-editor-modal ::v-deep .input:focus,
.task-editor-modal ::v-deep .select select:focus {
  border-color: #4299e1;
  box-shadow: 0 0 0 1px #4299e1;
}

.task-editor-modal ::v-deep .button {
  font-weight: 600;
  transition: all 0.2s ease;
}


