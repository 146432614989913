
.portfolio-holdings-area {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.holdings-table {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  color: black; /* Ensure text is black */
}

th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.selected {
  background-color: #f0f7ff;
}

.no-data {
  padding: 20px;
  text-align: center;
  color: #666;
  font-style: italic;
}

.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-style: italic;
  color: #666;
}
