
.box {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.title, .subtitle {
  color: #4a4a4a;
}

.canvas-container {
  max-width: 100%;
  max-height: 400px;
  margin-bottom: 20px;
}

.insights-section .insight {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.insights-section .insight h4 {
  margin-bottom: 5px;
}
