
.stk-file {
  flex-grow: 1;
  align-items: center;
  min-width: 16rem;
  gap: 0.5em 1em;
  display: grid;
  grid-template-columns: 16rem 1fr;

  &.is-vertical {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;

    .stk-file-button {
      max-width: none;
    }
  }
}

.stk-file-button {
  flex-grow: 1;
  max-width: 16rem;
  width: 100%;
}

.stk-file-input {
  pointer-events: none;
  position: absolute;
  visibility: hidden;
}

.stk-file-list {
  flex-grow: 1;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  line-height: 1.25;
  grid-column: 1 / span 2;
}

.stk-file-item {
  background-color: var(--stk-scheme-main);
}

.stk-file-toggle {
  color: inherit;
  cursor: pointer;
  text-decoration: underline;
}
