
.custom-upload-button a {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-upload-button a:hover {
  background-color: #e6e6e6;
  border-color: #888;
}
