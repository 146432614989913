
.stk-menu-item {
  display: flex;
  align-items: center;
  text-align: start;
  cursor: pointer;
  justify-content: start;
  line-height: 1.25;
  padding: 0.375em 0.625em;
  border-radius: 0.625em;
  font-weight: 500;
  color: var(--stk-text-normal);
  gap: 0.5em;
  transition-duration: var(--stk-transition-duration);
  transition-property: background-color, color;

  &:hover {
    background-color: var(--stk-primary-95);
    color: var(--stk-primary-30);
  }

  &.is-active {
    background-color: var(--stk-primary-90);
    color: var(--stk-primary-30);
  }
}
