
 .card-list {
 display: grid;
 grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr));
 column-gap: 1.5rem;
 /* row-gap: 1rem; */
 }

.esgcard {
   width: 12.5rem;
   height: 14rem;
   border: 1px solid;
   position: relative;
   flex-direction: column;
   justify-content: space-between;
   display: flex;
 }
 .esgcard.active {
   box-shadow: inset 0px 0px 18px 12px rgba(0,0,0,0.56);
 }
 .notification {
   padding: 1.25rem 1.5rem 1.25rem 1.5rem !important;
 }
 .esgcard.not-applicable {
   opacity: 0.5;
   pointer-events: none;
 }
 .esgcard.not-applicable.applied {
   pointer-events: all;
 }
 .esgcard .title {
   cursor: pointer;
 }
 .b-slider .b-slider-tick-label {
   top: calc(1rem / 2 + 2px) !important;
   font-size: 1rem;
 }
 .esgissue {
   cursor: pointer;
 }
 .b-slider-track {
   background: silver;
 }
 .esgcard .b-slider.is-primary .b-slider-fill {
   background: whitesmoke !important;
 }
  .filterflex {
   display: flex;
   justify-content: left;

   .control {
     margin-left: 5px;
   }
   .control:first-child {
     margin-left: 0px;
   }
 }


