
.stk-badge {
  --background-color: var(--stk-text-95);
  --color: var(--stk-text-30);
  background-color: var(--background-color);
  color: var(--color);
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  justify-content: start;
  line-height: 1rem;
  padding: 0.25em 0.375em;
  border-radius: 0.5em;
  gap: 0.125em;

  strong {
    color: inherit;
  }

  &.is-height-only {
    padding-left: 0;
    padding-right: 0;
  }

  &.is-primary {
    --background-color: var(--stk-primary-90);
    --color: var(--stk-primary-30);
  }

  &.is-success {
    --background-color: var(--stk-success-90);
    --color: var(--stk-success-30);
  }

  &.is-info {
    --background-color: var(--stk-info-95);
    --color: var(--stk-info-30);
  }

  &.is-warning {
    --background-color: var(--stk-warning-90);
    --color: var(--stk-warning-30);
  }

  &.is-danger {
    --background-color: var(--stk-danger-90);
    --color: var(--stk-danger-30);
  }

  &.is-disabled {
    --background-color: var(--stk-background);
    --color: var(--stk-text-weak);
  }
}

.stk-badge-body {
  font-size: 0.875rem;
  font-weight: 500;
}
