
.step-container {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.step-container::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background: linear-gradient(to right, #ec4899, #8b5cf6);
  border-radius: 9999px;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.step-container.tw-border-3::before {
  opacity: 1;
}

/* Custom styles for radio buttons */
.tw-form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  user-select: none;
  flex-shrink: 0;
  border-radius: 100%;
  border-width: 2px;
  border-color: #d1d5db;
  width: 1.2rem;
  height: 1.2rem;
}

.tw-form-radio:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

/* Add these new styles for the tree structure */
.tw-space-y-4 > li {
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 0.5rem;
}

.tw-space-y-4 > li:last-child {
  border-bottom: none;
}

.tw-space-y-1 {
  margin-top: 0.5rem;
}
