
.modal-card {
  max-width: 480px;
}

.video-preview {
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.button.is-rounded {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  flex-direction: column;
  padding: 2rem;
}

.button.is-rounded .icon {
  margin: 0 0 1rem 0;
  font-size: 2rem;
}
