
/* Add any additional styles here */
.form-checkbox {
  @apply tw-rounded;
  @apply tw-text-blue-500;
}

/* Smooth transitions */
.tw-transition-colors {
  @apply tw-transition-all tw-duration-200 tw-ease-in-out;
}
