
.meetings-list {
  max-height: 70vh;
  overflow-y: auto;
  margin: -0.75rem;
  padding: 0.75rem;
}

.meeting-link {
  display: block;
  text-decoration: none !important;
  color: inherit;
}

.meeting-card {
  transition: all 0.2s ease;
  border: 1px solid transparent;
  margin-bottom: 0.75rem;
}

.meeting-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
  border-color: #dbdbdb;
}

.meeting-title {
  display: flex;
  align-items: center;
}

.icon-text {
  align-items: center;
}

.icon-text .icon {
  margin-right: 0.5rem;
  color: #7a7a7a;
}

/* Custom scrollbar for webkit browsers */
.meetings-list::-webkit-scrollbar {
  width: 8px;
}

.meetings-list::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 4px;
}

.meetings-list::-webkit-scrollbar-thumb {
  background: #dbdbdb;
  border-radius: 4px;
}

.meetings-list::-webkit-scrollbar-thumb:hover {
  background: #b5b5b5;
}

.box {
  min-width: 600px;
}
