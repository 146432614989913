
.dossier-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dossier-banner {
  background-color: var(--stk-primary-95);
  border-radius: 1em;
  border: 1px solid var(--stk-primary-80);
  padding: 1.25em 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dossier-title {
  margin: 0;
  font-weight: 600;
  color: var(--stk-text-strong);
}

.dossier-subtitle {
  color: var(--stk-primary-40);
  font-size: 0.875em;
}

.component {
  background-color: var(--stk-background);
  border-radius: 1em;
  border: 1px solid var(--stk-border);
  padding: 1.25em 1.5em;
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(3, 1fr);
}

.item-title {
  color: var(--stk-text-strong);
  font-size: 0.875em;
  font-weight: 600;
  margin-bottom: 0.25em;
}

.item-description {
  color: var(--stk-text-soft);
  font-size: 0.875em;
  margin: 0 0 0.5em;
}

.master-upload {
  width: 60rem;
}
