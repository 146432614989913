
.dossier-item {
  width: 100%;
}

.group {
  background-color: rgb(0 0 0 / 3%);
  border: 1px solid var(--stk-text-80);
  border-radius: calc(calc(4 - var(--depth)) * 0.5rem);
  padding: 2rem;
  position: relative;
  margin-top: 0.75rem;
  transition-duration: var(--stk-transition-duration);
  transition-property: border-color;

  &.is-parent-of-object {
    background: none;
    border: none;
    padding: 0;

    > .group-heading {
      display: none;
    }
  }

  &.view-mode {
    background: none;
    border: none;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    .group-heading {
      left: 0;
    }
  }
}

.group-heading {
  left: 1.25rem;
  display: flex;
  top: -0.875rem;
  position: absolute;
  gap: 0;
  overflow: hidden;
  max-width: calc(100% - 2.5rem);
}

.group-title,
.group-description {
  font-weight: 500;
  display: flex;
  line-height: 1;
  padding: 0 0.75rem;
  height: 1.75rem;
  align-items: center;
  justify-content: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.group-title {
  background-color: var(--stk-text-20);
  border-bottom-left-radius: 9999px;
  border-top-left-radius: 9999px;
  color: var(--stk-text-80);
  font-size: 0.875rem;
  font-weight: 600;

  &:last-child {
    border-bottom-right-radius: 9999px;
    border-top-right-radius: 9999px;
  }
}

.group-description {
  background-color: var(--stk-text-40);
  color: var(--stk-text-80);
  font-size: 0.875rem;
  border-bottom-right-radius: 9999px;
  border-top-right-radius: 9999px;
  margin-right: 1em;
}

.delete-item {
  position: absolute;
  right: 1rem;
  top: -0.9375rem;
}

/* Variants */
.group.is-depth-0 {
  background-color: transparent;
  border: none;
  padding: 0;
  margin-top: 0;

  > .group-title,
  > .group-description {
    display: none;
  }
}

/* Array item styling */
.array-items-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

.array-items-title {
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.array-item-wrapper {
  border: 1px dashed var(--stk-text-70);
  border-radius: 0.5rem;
  padding: 1.5rem;
  position: relative;
}

.array-item-header {
  background-color: var(--stk-text-60);
  color: white;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  position: absolute;
  top: -0.75rem;
  left: 1rem;
}

.empty-array {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  gap: 1rem;
}

.array-item {
  position: relative;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px dashed var(--stk-text-70);
  border-radius: 0.5rem;
}

.array-controls {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.array-item.empty-placeholder {
  border-style: dotted;
  background-color: rgba(0, 0, 0, 0.02);
}

.array-item-header.placeholder {
  background-color: var(--stk-text-70);
}
