
#design-portfolio-view {
  display:        flex;
  flex-direction: column;
  align-items:    stretch;
  gap:            3em;
}

#design-portfolio-inputs {
  flex-basis: 55rem;
}

.card {
  border: 1px solid gray;
}

.disclosures {
  flex-basis: 80em;
}

.disclosures p {
  margin: 1em;
}
