
section {
  @apply tw-flex tw-flex-col tw-items-center tw-gap-5;
}

section > h2 {
  @apply tw-text-lg tw-font-bold;
}

section > div {
  @apply tw-flex tw-flex-col tw-gap-5;
}

button {
  @apply tw-px-4 tw-py-2 tw-rounded-md tw-border-none;
  @apply tw-font-mono tw-text-lg;
  @apply tw-bg-rose-300 hover:tw-bg-rose-500 hover:tw-cursor-pointer;
}
