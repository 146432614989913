
.stk-pill-item {
  display: flex;
  align-items: center;
  justify-content: start;
  font-weight: 500;
  color: var(--stk-text-normal);
  cursor: pointer;
  gap: 0.25em;
  line-height: 1.25;
  padding: 0.375em 0.625em;
  border-radius: 0.5em;
  transition-duration: var(--stk-transition-duration);
  transition-property: background-color, color;
  text-align: start;
  justify-content: center;
  text-align: center;

  .stk-icon {
    color: var(--stk-text-weak);
  }

  &:hover {
    color: var(--stk-text-strong);

    .stk-icon {
      color: var(--stk-primary);
    }
  }

  &.is-active {
    background-color: var(--stk-scheme-main);
    box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.10) inset;
    color: var(--stk-text-strong);

    .stk-icon {
      color: var(--stk-primary);
    }
  }
}

[data-stk-theme="dark"] {
  .stk-pill-item {
    &.is-active {
      background-color: var(--stk-text-30);
      box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.50) inset;
    }
  }
}
