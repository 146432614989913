
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--stk-size-8);
}

.tree {
  grid-column: 1;
}

.groups {
  grid-column: 2;
}
