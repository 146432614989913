
.tree-node {
  --padding: 0.375rem;
  --size: 1.25rem;
  --border: 0.125rem;
  --square: calc(var(--size) + calc(2 * var(--padding)));
}

.node-content {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: start;
  line-height: 1.25;
  border-radius: 0.625em;
  font-weight: 500;
  color: var(--stk-text-strong);
  position: relative;
  transition-duration: var(--stk-transition-duration);
  transition-property: background-color, color;
  padding-left: var(--square);
  height: var(--square);

  &:hover {
    background-color: var(--stk-text-95);

    .node-link {
      opacity: 1;
    }
  }

  &.is-expandable {
    cursor: pointer;

    &:hover {
      background-color: var(--stk-primary-95);
      color: var(--stk-primary-30);
    }
  }

  &.is-active {
    box-shadow: inset 0 0 0 1px var(--stk-border), 0 0 0 1px var(--stk-border);
    font-weight: 700;
  }
}

.node-icon {
  height: var(--size);
  width: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  border-radius: 9999px;
  left: var(--padding);
  z-index: 1;
}

.node-sign {
  --stk-icon-dimensions: 1rem;
  background-color: var(--stk-primary);
  font-size: 0.75rem;
}

.node-square {
  --stk-icon-dimensions: 0.75rem;
  background-color: var(--stk-border);
  color: transparent;
}

/* 2nd level */
.node-children .node-content {
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: var(--square);
    height: var(--square);
    border: solid var(--stk-border);
    border-width: 0 0 var(--border) var(--border);
    top: calc(calc(-0.5 * var(--square)) + calc(0.5 * var(--border)));
    left: calc(calc(-0.5 * var(--square)) + calc(-0.5 * var(--border)));
  }
}

.node-children {
  margin-left: var(--square);
}

.current-entity {
  color: var(--stk-text-strong);
  margin: 0 1em 0 auto;
}

.node-link {
  margin: 0 1em 0 auto;
  color: var(--stk-primary);
  transition-duration: var(--stk-transition-duration);
  transition-property: opacity;
  opacity: 0;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}
