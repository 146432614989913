
.flexvaluesmenu {
  display: flex;
  justify-content: space-between;
}
.preview {
  position: relative;
  transition: filter 500ms, opacity 500ms, saturation 500ms;
}
.preview.loading {
  filter: blur(10px) opacity(0.5) saturate(0.5);
}
.spinning {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.comparison-portfolio-is-benchmark {
  margin-top: 0.33rem;
}
