
#add-meeting-dropdown {
  max-height: 500px !important;
}

.dropdown-menu {
  min-width: 300px;
}

.dropdown-content {
  padding: 0.5rem 0;
}

.dropdown-item {
  display: flex;
  align-items: start;
  padding: 0.75rem 1rem;
}

.dropdown-item .icon {
  margin-right: 0.75rem;
  margin-top: 0.25rem;
}

.dropdown-item-content {
  flex: 1;
}

.dropdown-item-title {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.dropdown-item-description {
  font-size: 0.875rem;
  color: #666;
}

.primary-action {
  background-color: #f0f9ff;
}

.primary-action:hover {
  background-color: #e0f2fe !important;
}

.primary-action .icon {
  color: #0284c7;
}

.primary-action .dropdown-item-title {
  color: #0284c7;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}
