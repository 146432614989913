
.verticalflex {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.button.is-active {
  border-width: 2px;
}
.smallsection {
  padding: 2rem 1.5rem;
}
