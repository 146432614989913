
ul.tw-list-disc {
  list-style-type: disc;
}
.v-table {
  width: 100%;
}

.v-table th {
  font-weight: bold;
  text-align: left;
  padding: 12px;
  background-color: #f5f5f5;
}

.v-table td {
  padding: 12px;
  border-bottom: 1px solid #eeeeee;
}
