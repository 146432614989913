
.grid-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: 12em 1fr;
}

#header {
  grid-row: 1;
  grid-column: 1/3;
}

#main-content {
  grid-row: 2;
  grid-column: 1;
  padding: 2rem;
}

#sidebar {
  grid-row: 2;
  grid-column: 2;
  padding: 1rem;
  border-top: 1px solid #D0D4DC;
  border-left: 1px solid #D0D4DC;
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
}

.entity-overview-container {
  display: flex;
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}

.entity-overview {
  flex: 1;
  max-width: calc(100% - 250px);
}

.sidebar {
  width: 250px;
  position: sticky;
  top: 1rem;
  height: calc(100vh - 2rem);
  background: #f5f5f5;
  border-radius: 6px;
  padding: 1rem;
}


.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.section-nav {
  flex: 1;
  overflow-y: auto;
}

.menu-label {
  color: #7a7a7a;
  font-size: 0.8rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-list a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  color: #4a4a4a;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.2s;
}

.menu-list a:hover {
  background-color: #e8e8e8;
}

.menu-list a.is-active {
  background-color: #3273dc;
  color: white;
}

.last-updated {
  border-top: 1px solid #dbdbdb;
  padding-top: 1rem;
}

.entity-overview {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.card {
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  margin-bottom: 1rem;
}

.card-content {
  padding: 1.5rem;
}

.field-item {
  margin-bottom: 0.75rem;
  border-left: 3px solid #3273dc;
  padding-left: 0.75rem;
}

.field-label {
  display: block;
  font-size: 0.875rem;
  color: #7a7a7a;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.field-value {
  display: block;
  font-size: 1.1rem;
  color: #363636;
  font-weight: 500;
}

.compact-grid {
  height: 200px;
  font-size: 0.9rem;
}

.master-grid {
  height: 400px;
}

/* Print Optimizations */
@media print {
  @page {
    size: letter;
    margin: 0.5in;
  }

  .print-section {
    page-break-inside: avoid;
  }

  .entity-overview {
    padding: 0;
  }

  .card {
    box-shadow: none;
    border: 1px solid #dbdbdb;
  }

  .tabs {
    display: none;
  }

  .tab-pane {
    display: block !important;
  }

  .master-grid {
    height: auto;
  }
}

.section-alerts {
  margin-bottom: 1rem;
}

.notification {
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.notification.is-warning {
  background-color: #fff8e6;
  color: #946c00;
}

.notification.is-info {
  background-color: #e6f5ff;
  color: #004e7c;
}

.notification.is-danger {
  background-color: #fff5f5;
  color: #c53030;
}

.notification.is-success {
  background-color: #f0fff4;
  color: #276749;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.field-item .input {
  max-width: 100%;
}

.input.is-static {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  padding-left: 0;
}

.input.is-static:focus {
  box-shadow: none;
}

.sources-section {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #dbdbdb;
}

.search-box {
  margin-bottom: 0.75rem;
}

.source-filters {
  margin-bottom: 1rem;
}

.source-filters .tags {
  margin-bottom: 0.5rem;
}

.source-list {
  max-height: 300px;
  overflow-y: auto;
}

.source-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: #4a4a4a;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.2s;
  gap: 0.75rem;
}

.source-item:hover {
  background-color: #f5f5f5;
}

.source-details {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.source-title {
  font-size: 0.9rem;
  line-height: 1.2;
}

.source-date {
  font-size: 0.75rem;
  color: #7a7a7a;
}

.document-item {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}

.document-header {
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.document-header:hover {
  background-color: #f5f5f5;
}

.document-details {
  padding: 1rem;
  border-top: 1px solid #dbdbdb;
  background-color: #fafafa;
}

.field-item {
  margin-bottom: 0.75rem;
  padding-left: 0.75rem;
  border-left: 3px solid #3273dc;
}

.field-label {
  display: block;
  font-size: 0.875rem;
  color: #7a7a7a;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.field-value {
  display: block;
  font-size: 1rem;
  color: #363636;
}

.validation-rule-item {
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.validation-details {
  background-color: white;
  border-radius: 4px;
  padding: 1rem;
}

.document-actions {
  border-top: 1px solid #dbdbdb;
  padding-top: 1rem;
}
