
.stuff {
  font-family: "DM Sans";
}
.formcontrol {
  margin: auto;
  max-width: 40rem;
  min-height: 20rem;
}
.preheader {
  font-weight: bold;
  letter-spacing: -.01em;
  font-size: 1.5rem;
}
.criteria {
  font-weight: medium;
  letter-spacing: -.01em;
  font-size: .7rem;
}
.dustin-title {
  font-weight: bold;
  letter-spacing: -.01em;
  font-size: 2.5rem;
}
.dustin-label {
  font-weight: bold;
  letter-spacing: -.01em;
  font-size: .8rem;
}
.highlights {
  font-weight: bold;
  letter-spacing: -.01em;
  font-size: 2rem;
}
.line0 {
    flex-grow: 1;
    border-bottom: 2px solid var(--black-95);
}
.indented {
  padding-left: 3rem;
}
.button {
  border-radius: 7px;
}
.crisp > span {
  font-size: .8rem;
}
.label {
  font-size: .8rem;
  margin-bottom: 5px;
}
