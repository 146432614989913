
/* @import 'shepherd.js/dist/css/shepherd.css'; */

.shepherd-theme-custom {
  max-width: 400px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  font-family: Arial, sans-serif;
}

.shepherd-theme-custom::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.shepherd-theme-custom[data-popper-placement^='top']::before {
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent white transparent;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.shepherd-theme-custom[data-popper-placement^='bottom']::before {
  border-width: 10px 10px 0 10px;
  border-color: white transparent transparent transparent;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.shepherd-theme-custom[data-popper-placement^='left']::before {
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.shepherd-theme-custom[data-popper-placement^='right']::before {
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.shepherd-theme-custom::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Ccircle cx='50' cy='50' r='48' fill='%234F46E5'/%3E%3Cpath d='M30 40 L50 60 L70 40' stroke='white' stroke-width='8' fill='none'/%3E%3C/svg%3E");
  background-size: contain;
}

.shepherd-theme-custom[data-popper-placement^='top']::after {
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
}

.shepherd-theme-custom[data-popper-placement^='bottom']::after {
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
}

.shepherd-theme-custom[data-popper-placement^='left']::after {
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
}

.shepherd-theme-custom[data-popper-placement^='right']::after {
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
}

.shepherd-text {
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
}

.shepherd-button {
  background: #4F46E5;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.shepherd-button:hover {
  background-color: #4338CA;
}
.ai-sprite {
  width: 100px;
  height: 100px;
  background-size: 100px 100px; /* Adjust based on your sprite sheet */
  animation: none;
}

.ai-sprite.ai-talking {
  animation: ai-talk 0.8s steps(4) infinite;
}

@keyframes ai-talk {
  from { background-position: 0 0; }
  to { background-position: -200px 0; }
}

.action-button-container {
  position: relative;
  padding: 3px;
  background: linear-gradient(to right, #ec4899, #8b5cf6);
  border-radius: 9999px;
}

.action-button {
  background: white;
  border-radius: 9999px;
}

.action-button:hover {
  transform: translateY(-1px);
}

/* Add these styles for the step containers */
.step-container {
  display: none;
}

/* Add this new style for the chat container */
.tw-flex-grow {
  min-height: 0;
}
