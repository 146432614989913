
.nav-menu-section {
  position: relative;
  min-width: 15em;
}

.links {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  gap: 0.25em;

  min-width: 12em;
  padding: 0.5em 0 0.5em 0;
  background-color: inherit;
}

/* Indent links under section title */
/*
.full .links {
  padding-left: 1em;
}
*/
