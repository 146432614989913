
.scaled-frame {
  /* zoom: 0.75; */
  -moz-transform: scale(0.50);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.50);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.50);
  -webkit-transform-origin: 0 0;
}
.threeQuarters {
  -moz-transform: scale(0.75);
}

