.image.is-flex img {
  align-self: center;
}

/* VARIABLES */
:root {
  --standardMargin: 10px;
  --base1: #805EE6;
  --base2: #EFEFEF;
  --base3: #FCFCFC;
  --base4: #48c78e;
  --base5: #FCFCFD;
  --base6: #52AFE3;
  --white: #FFFFFF;
  --base1Light: #f5f1fe;

  // New color scheme
  --primary-3: #805EE6
}

.primary-3 {
  background-color: var(--primary-3);
}

.primary-3-text {
  color: var(--primary-3);
}

.primary-3-border-color {
  border-color: var(--primary-3);
}


// * {
//   outline: 1px solid #f00 !important;
// }
.testclass {
  background-color: orange;
}



/* COLORS */
.base1 {
  background-color: var(--base1);
}

.base2 {
  background-color: var(--base2);
}

.base3 {
  background-color: var(--base3);
}

.base4 {
  background-color: var(--base4);
}

.base5 {
  background-color: var(--base5);
}

.lightBase1 {
  background-color: rgba(141, 103, 239, 0.2);
}

.white {
  background-color: var(--white);
}

.importantWhite {
  background-color: var(--white) !important;
}

.black {
  background-color: black;
}

.transparentBackground {
  background-color: transparent;
}

.base1Text {
  color: var(--base1);
}

.base2Text {
  color: var(--base2);
}

.base4Text {
  color: var(--base4);
}

.base6Text {
  color: var(--base6);
}

.blackText {
  color: black;
}

.opaqueBlackText {
  color: black;
  opacity: 0.3;
}

.whiteText {
  color: #fff;
}

.greenText {
  color: #36B779;
}

.base1Light {
  background-color: var(--base1Light);
}

.grayText2 {
  color: #969EB0;
}

.grayText {
  color: #9B9B9B;
}

.redText {
  color: #A83B5A;
}

.gray {
  background-color: #9B9B9B;
}

.veryOpaque {
  opacity: 0.1;
}

.opaque {
  opacity: 0.5;
}

.notOpaque {
  opacity: 0;
}

.slightOpaque {
  opacity: 0.75;
}

.whiteScrollbar {
  scrollbar-color: #fff
}

/* FLEX */
.flexDefault {
  display: flex;
  flex: 0 1 150px;
}

.flex {
  display: flex;
  flex: 0;
}

.flex1 {
  display: flex;
  flex: 1;
}

.flex2 {
  display: flex;
  flex: 2;
}

.flex3 {
  display: flex;
  flex: 3;
}

.flex4 {
  display: flex;
  flex: 4;
}

.flex8 {
  display: flex;
  flex: 8;
}

.flex10 {
  display: flex;
  flex: 10;
}

.flexBasis300Px {
  flex-basis: 300px;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.tableCellDisplay {
  display: table-cell;
}

.justifyFlexStart {
  justify-content: flex-start;
}

.mainAxisFlexStart {
  justify-content: flex-start;
}

.crossAxisFlexStart {
  align-items: flex-start;
}

.mainAxisFlexEnd {
  justify-content: flex-end;
}

.mainAxisSpaceAround {
  justify-content: space-around;
}

.mainAxisSpaceBetween {
  justify-content: space-between;
}

.crossAxisSpaceBetween {
  align-items: space-between;
}

.crossAxisCenter {
  align-items: center;
}

.crossAxisFlexEnd {
  align-items: flex-end;
}

.mainAxisCenter {
  justify-content: center;
}

.alignSelfCenter {
  align-self: center;
}

.flexWrap {
  flex-wrap: wrap;
}

.centerCenter {
  justify-content: center;
  align-items: center;
}

.breakWordWrap {
  overflow-wrap: break-word;
}

.wordWrapBreak {
  word-wrap: break-word;
}

.breakSpaces {
  white-space: break-spaces;
}

.noWrap {
  white-space: nowrap;
}


/* DISPLAY */
.blockDisplay {
  display: block;
}

.inlineBlockDisplay {
  display: inline-block;
}

.inlineDisplay {
  display: inline;
}

.hiddenBackfaceVisibility {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.hiddenDisplay {
  display: none;
}

/* POSITIONING */
.absolutePosition {
  position: absolute;
}

.relativePosition {
  position: relative;
}

.fixedPosition {
  position: fixed;
}

.centerText {
  text-align: center;
}

.leftText {
  text-align: left;
}

.rightText {
  text-align: right;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.bottomRight {
  /* Make sure parent has position: relative */
  position: absolute;
  bottom: 0;
  right: 0;
}

.topRight {
  position: absolute;
  top: 0;
  right: 0;
}

.topLeft {
  position: absolute;
  top: 0;
  left: 0;
}

.allSides {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.stickBottom {
  position: sticky;
  bottom: 0;
}

.bottom {
  bottom: 0;
}

.right0 {
  right: 0;
}

.verticalAlignMiddle {
  vertical-align: middle;
}

/* SIZES */
.full {
  width: 100%;
  height: 100%;
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.halfHeight {
  height: 50%;
}

.halfWidth {
  width: 50%;
}

.threeQuartersWidth {
  width: 75%;
}

.maxWidthThreeQuarters {
  max-width: 75%;
}

.doubleWidth {
  width: 200%;
}

.zeroHeight {
  height: 0;
}

.thousandHeight {
  height: 1000px;
}

.maxHeight250Px {
  max-height: 250px;
}

.maxHeight300Px {
  max-height: 300px;
}

.maxHeight500Px {
  max-height: 500px;
}

.width300Px {
  width: 300px;
}

.width600Px {
  width: 600px;
}

.autoWidth {
  width: auto;
}

.maxWidth300Px {
  max-width: 300px;
}

.height44Px {
  height: 44px;
}

.height20Px {
  height: 20px;
}

.height5Px {
  height: 5px;
}

.height3Px {
  height: 3px;
}

.height2Px {
  height: 2px;
}

.height1Px {
  height: 1px;
}

.lineHeight17Px {
  line-height: 17px;
}

.height100Px {
  height: 100px;
}

.maxHeightScreen {
  max-height: 100vh;
}

.maxHeightHalfScreen {
  max-height: 40vh;
}

.minHeight70vh {
  min-height: 70vh;
}

.maxHeight70vh {
  max-height: 70vh;
}

.maxHeight90vh {
  max-height: 85vh;
}

.twentyFivePxHeight {
  height: 26px;
}

.maxWidthHalf {
  max-width: 50%;
}

.maxWidth30Rem {
  max-width: 30rem;
}

.maxWidth80Px {
  max-width: 80px;
}

.maxWidth400Px {
  max-width: 400px;
}

.maxHeight400Px {
  max-height: 400px;
}

.maxHeight500Px {
  max-height: 500px;
}

.maxWidth40Rem {
  max-width: 40rem;
}

.maxWidth1100Px {
  max-width: 1100px;
}

.minWidthFull {
  min-width: 100%;
}

.minWidth250Px {
  min-width: 250px;
}

.minWidth300Px {
  min-width: 300px;
}

.minHeight60Px {
  min-height: 60px;
}

.maxHeight60Px {
  max-height: 60px;
}

.maxHeight100Px {
  max-height: 100px;
}

.maxHeightFull {
  max-height: 100%;
}

.maxHeightThreeQuarters {
  max-height: 75%;
}

.minHeight200Px {
  min-height: 200px;
}

.minHeight250Px {
  min-height: 250px;
}

.minHeight300Px {
  min-height: 300px;
}

.minHeightFull {
  min-height: 100%;
}

.minHeightScreen {
  min-height: 100vh;
}

.height25Px {
  height: 25px;
}

.height50Px {
  height: 50px;
}

.width25Px {
  width: 25px;
}

.height55Px {
  height: 55px;
}

.square175Px {
  min-height: 175px;
  max-height: 175px;
  min-width: 175px;
  max-width: 175px;
}

.square100Px {
  min-height: 100px;
  max-height: 100px;
  min-width: 100px;
  max-width: 100px;
}

.square32Px {
  min-height: 32px;
  max-height: 32px;
  min-width: 32px;
  max-width: 32px;
}

.maxWidth550Px {
  max-width: 550px;
}

.maxSupportedWidth {
  max-width: 80vw;
}

.maxSupportedWidthv2 {
  max-width: 60vw;
}

.width150Px {
  width: 150px;
}

.width300Px {
  width: 300px;
}

.width250Px {
  width: 250px;
}

/* BORDERS */
.noBorder {
  border: 0;
}

.noneBorder {
  border: none;
}

.twoPxPurpleSideBorders {
  border-right: 2px solid var(--base1);
  border-left: 2px solid var(--base1);
}

.blackBorder {
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.importantBlackBorder {
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.leftBlackBorder {
  border-left: 1px solid rgba(0, 0, 0, 0.08);
}

.dashedBlackCurvedBorder {
  border-style: dashed;
  border-color: rgba(0, 0, 0, 0.08);
  border-radius: 10px
}

.bottomBlackBorder {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.topBlackBorder {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.rightBlackBorder {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.rightGrayBorder {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.allButLeftBlackBorder {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.base1CurvedBorder {
  border: 1px solid var(--base1);
  border-radius: 10px
}

.topLeftBottomRightCurvedBorder {
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.topLeftCurvedBorder {
  border-top-left-radius: 10px;
}

.topRightCurvedBorder {
  border-top-right-radius: 10px;
}

.noTopBorder {
  border-top: none;
}

.borderBox {
  border-box: border-box;
}

.boxShadow {
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.1);
}

.boxShadowLight {
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.05);
}

.importantBoxShadow {
  box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.03) !important;
}

.boxShadowBottom {
  box-shadow: 0 8px 2px -2px rgba(0, 0, 0, 0.03);
}

.topBorderRadius {
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}

.leftBorderRadius {
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}

.rightBorderRadius {
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
}

.bottomBorderRadius {
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}

.allAroundBorderRadius {
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;

}

.largeCurvedBorder {
  border-radius: 25px;
}

.curvedBorder {
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  border-radius: 10px
}

.tinyCurvedBorder {
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  border-radius: 2px
}

.slightCurvedBorder {
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  border-radius: 5px
}

.leftSlightCurvedBorder {
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.fiftyPercentBorderRadius {
  border-radius: 50%;
}

.rightBorder {
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.bottomBorder {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.inlineSizeMinContent {
  inline-size: min-content;
}



/* PADDING */
.topBottomPadding {
  padding-top: var(--standardMargin);
  padding-bottom: var(--standardMargin);
}

.halfBottomMargin {
  margin-bottom: calc(var(--standardMargin) / 2);
}

.halfTopMargin {
  margin-top: calc(var(--standardMargin) / 2);
}

.autoMargin {
  margin: auto;
}

.bottomMargin {
  margin-bottom: var(--standardMargin);
}

.topBottomMargin {
  margin-top: var(--standardMargin);
  margin-bottom: var(--standardMargin);
}

.topBottomMargin2 {
  margin-top: calc(2 * var(--standardMargin));
  margin-bottom: calc(2 * var(--standardMargin));
}

.bottomMargin2 {
  margin-top: calc(2 * var(--standardMargin));
  margin-bottom: calc(2 * var(--standardMargin));
}

.bottomMargin3 {
  margin-bottom: calc(3 * var(--standardMargin));
}

.topBottomHalfMargin {
  margin-top: calc(var(--standardMargin) / 2);
  margin-bottom: calc(var(--standardMargin) / 2);
}

.bottomMarginHalf {
  margin-bottom: calc(var(--standardMargin) / 2);
}

.topMarginHalf {
  margin-top: calc(var(--standardMargin) / 2);
}

.topMargin {
  margin-top: calc(var(--standardMargin));
}

.topMargin2 {
  margin-top: calc(2 * var(--standardMargin));
}

.topMargin3 {
  margin-top: calc(3 * var(--standardMargin));
}

.topMargin4 {
  margin-top: calc(4 * var(--standardMargin));
}

.bottomMargin25Px {
  margin-bottom: 25px;
}

.leftPaddingHalf {
  padding-left: calc(var(--standardMargin) / 2);
}

.leftPadding {
  padding-left: var(--standardMargin);
}

.leftPadding2 {
  padding-left: calc(2 * var(--standardMargin));
}

.slightBottomPadding {
  padding-bottom: calc(var(--standardMargin) / 3);
}

.bottomPaddingHalf {
  padding-bottom: calc(var(--standardMargin) / 2);
}

.noBottomPadding {
  padding-bottom: 0;
}

.bottomPadding {
  padding-bottom: var(--standardMargin);
}

.bottomPadding2 {
  padding-bottom: calc(2 * var(--standardMargin));
}

.bottomPadding6 {
  padding-bottom: calc(6 * var(--standardMargin));
}

.bottomMargin2 {
  margin-bottom: calc(2 * var(--standardMargin));
}

.bottomMargin6 {
  margin-bottom: calc(6 * var(--standardMargin));
}

.topPadding {
  padding-top: var(--standardMargin);
}

.topPadding2 {
  padding-top: calc(2 * var(--standardMargin));
}

.topPadding2AndHalf {
  padding-top: calc(2.5 * var(--standardMargin));
}

.topPadding3 {
  padding-top: calc(3 * var(--standardMargin));
}

.topPadding5 {
  padding-top: calc(5 * var(--standardMargin));
}

.topBottomPadding2 {
  padding-top: calc(2 * var(--standardMargin));
  padding-bottom: calc(2 * var(--standardMargin));
}

.topBottomPadding3 {
  padding-top: calc(3 * var(--standardMargin));
  padding-bottom: calc(3 * var(--standardMargin));
}

.topBottomPadding5 {
  padding-top: calc(5 * var(--standardMargin));
  padding-bottom: calc(5 * var(--standardMargin));
}

.oneHundredRightMargin {
  margin-right: 100px;
}

.oneHundredSideMargin {
  margin-left: 100px;
  margin-right: 100px;
}

.oneHundredTopMargin {
  margin-top: 100px;
}

.oneHundredTopPadding {
  padding-top: 100px;
}

.twoHundredSideMargin {
  margin-left: 200px;
  margin-right: 200px;
}

.twoHundredSidePadding {
  margin-left: 200px;
  margin-right: 200px;
}

.oneHundredSidePadding {
  margin-left: 100px;
  margin-right: 100px;
}

.allAroundMargin {
  margin-top: calc(var(--standardMargin));
  margin-bottom: calc(var(--standardMargin));
  margin-right: calc(var(--standardMargin));
  margin-left: calc(var(--standardMargin));
}

.allAroundMargin2 {
  margin-top: calc(2 * var(--standardMargin));
  margin-bottom: calc(2 * var(--standardMargin));
  margin-right: calc(2 * var(--standardMargin));
  margin-left: calc(2 * var(--standardMargin));
}

.allAroundMargin4 {
  margin-top: calc(4 * var(--standardMargin));
  margin-bottom: calc(4 * var(--standardMargin));
  margin-right: calc(4 * var(--standardMargin));
  margin-left: calc(4 * var(--standardMargin));
}

.sideMargins {
  margin-right: calc(1 * var(--standardMargin));
  margin-left: calc(1 * var(--standardMargin));
}

.sideMargins2 {
  margin-right: calc(2 * var(--standardMargin));
  margin-left: calc(2 * var(--standardMargin));
}

.sideMargins4 {
  margin-right: calc(4 * var(--standardMargin));
  margin-left: calc(4 * var(--standardMargin));
}

.allAroundPaddingHalf {
  padding-top: calc(var(--standardMargin) / 2);
  padding-bottom: calc(var(--standardMargin) / 2);
  padding-right: calc(var(--standardMargin) / 2);
  padding-left: calc(var(--standardMargin) / 2);
}

.allAroundPadding {
  padding-top: calc(var(--standardMargin));
  padding-bottom: calc(var(--standardMargin));
  padding-right: calc(var(--standardMargin));
  padding-left: calc(var(--standardMargin));
}

.allAroundPadding2 {
  padding-top: calc(2 * var(--standardMargin));
  padding-bottom: calc(2 * var(--standardMargin));
  padding-right: calc(2 * var(--standardMargin));
  padding-left: calc(2 * var(--standardMargin));
}

.allAroundPadding3 {
  padding-top: calc(3 * var(--standardMargin));
  padding-bottom: calc(3 * var(--standardMargin));
  padding-right: calc(3 * var(--standardMargin));
  padding-left: calc(3 * var(--standardMargin));
}

.allAroundPadding4 {
  padding-top: calc(4 * var(--standardMargin));
  padding-bottom: calc(4 * var(--standardMargin));
  padding-right: calc(4 * var(--standardMargin));
  padding-left: calc(4 * var(--standardMargin));
}

.allAroundPadding6 {
  padding-top: calc(6 * var(--standardMargin));
  padding-bottom: calc(6 * var(--standardMargin));
  padding-right: calc(6 * var(--standardMargin));
  padding-left: calc(6 * var(--standardMargin));
}

.sidePadding {
  padding-right: calc(var(--standardMargin));
  padding-left: calc(var(--standardMargin));
}

.sidePadding2 {
  padding-right: calc(2 * var(--standardMargin));
  padding-left: calc(2 * var(--standardMargin));
}

.sidePadding4 {
  padding-right: calc(4 * var(--standardMargin));
  padding-left: calc(4 * var(--standardMargin));
}

.sidePadding6 {
  padding-right: calc(6 * var(--standardMargin));
  padding-left: calc(6 * var(--standardMargin));
}

.rightPadding {
  padding-right: var(--standardMargin);
}

.leftMarginHalf {
  margin-left: calc(var(--standardMargin) / 2);
}

.leftMargin {
  margin-left: var(--standardMargin);
}

.rightMarginHalf {
  margin-right: calc(var(--standardMargin) / 2);
}

.rightMargin {
  margin-right: var(--standardMargin);
}

.rightMargin2 {
  margin-right: calc(2 * var(--standardMargin));
}

.rightMargin3 {
  margin-right: calc(3 * var(--standardMargin));
}

.leftMargin2 {
  margin-left: calc(2 * var(--standardMargin));
}

/* FONT SIZE */
.fontSizeHalf {
  font-size: 0.8em;
}

.fontSizeThreeQuarters {
  font-size: 0.9em;
}

.fontSize1 {
  font-size: 1em;
}

.fontSize1AndQuarter {
  font-size: 1.25em;
}

.fontSize1AndHalf {
  font-size: 1.5em;
}

.fontSize2 {
  font-size: 2em;
}

.fontSize2AndHalf {
  font-size: 2.5em;
}

.fontSize2Rem {
  font-size: 2rem;
}

.fontSize3 {
  font-size: 3em;
}

.fontSize3Half {
  font-size: 3.5em;
}

.fontSize4 {
  font-size: 4em;
}

.fontSize5 {
  font-size: 5em;
}

.fontSize6 {
  font-size: 6em;
}

.fontSize7 {
  font-size: 7em;
}

.fontSize8 {
  font-size: 8em;
}

.extraBold {
  font-weight: 900;
}

.bold {
  font-weight: bold;
}

.heavy {
  font-weight: 600;
}

.normalFontWeight {
  font-weight: 400;
}

.lightText {
  font-weight: 200;
}

.underline {
  text-decoration: underline;
}

.lineThrough {
  text-decoration: line-through;
}

/* OVERFLOW */
.hiddenOverflow {
  overflow: hidden;
}

.autoOverflow {
  overflow: auto;
}


/* POINTER */
.pointer {
  cursor: pointer;
}

.noPointerEvents {
  pointer-events: none;
}

.dimOnHover:hover {
  background-color: rgba(0, 0, 0, 0.05);
  /*where 0.5 stands for 50% opacity*/
}

.base1ShadeOnHover:hover {
  background-color: rgba(128, 94, 230, 0.5);
  /*where 0.5 stands for 50% opacity*/
}

/* Z-INDEX */
.oneHundredZindex {
  z-index: 100;
}

.oneHundredOneZindex {
  z-index: 101;
}

/* TRANSITIONS */
.fifthSecondTransform {
  transition: transform 0.2s;
}

.preserve3dTransform {
  transform-style: preserve-3d;
}

.flipOnHover:hover {
  transform: rotateY(180deg);
}

.flipNoHover {
  transform: rotateY(180deg);
}

.thousandPxPerspective {
  perspective: 1000px;
}

/* SHAPES */
.circle {
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px
  ;
  border-radius: 50%;
}

.circle2 {
  min-width: 120px;
  min-height: 120px;
  ;
  border-radius: 50%;
}

/* SCROLL */
.yScroll {
  overflow-y: scroll;
}

.flipX {
  transform: rotateX(180deg);
}

.xScroll {
  overflow-x: scroll;
}

.borderOnHover:hover {
  border: 3px #cc0 dotted;
}
.nomobile {
  display:none;
}