
.component {
  --spacing: 1.5rem;

  &.is-top-level {
    display: none;

    &.is-displayed {
      display: block;
    }
  }

  &.is-object {
    grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
    gap: 1rem;
    padding-bottom: var(--spacing);
    grid-column: 1 / -1;
    width: 100%;

    &.is-displayed {
      display: grid;
    }
  }

  &.is-display {
    padding-left: 0.5rem;

    &.is-nested {
      + .component.is-object {
        padding-top: var(--spacing);
      }
    }
  }
}

.component-header {
  display: flex;
  width: 100%;
  grid-column: 1 / -1;

  &.is-ids {
    flex-wrap: wrap;
    background: var(--stk-text-95);
    padding: 0.5rem 0.75rem;
    border-radius: 0.5rem;
  }
}

.component-parent {
  display: flex;
  font-size: 1.25em;
  opacity: 0.7;
  line-height: 1;

  &::after {
    content: "/";
    margin: 0 0.25em;
    opacity: 0.5;
  }
}

.component-id {
  &.is-badge {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1.25em;
    color: var(--stk-text-20);
    font-weight: 600;
    line-height: 1;
  }

  &.is-label {
    color: var(--stk-primary);
    font-size: 0.875em;
  }

  &.is-null {
    color: var(--stk-text-70);
  }
}

.display-value {
  font-size: 1.125em;
  position: relative;

  &.is-null {
    color: var(--stk-text-80);
    font-style: italic;
  }

  &:hover .clipboard-icon {
    opacity: 1;
  }
}

.clipboard-button {
  cursor: pointer;
  display: flex;
  margin-left: calc(-0.5em - 1px);
  margin-top: calc(-0.25em - 1px);
  border-color: transparent;
  border-radius: 0.5em;
  border-style: solid;
  border-width: var(--stk-control-border-width);
  padding: 0.25em 0.5em;
  transition-duration: var(--stk-transition-duration);
  transition-property: background-color, border-color, box-shadow;

  &:hover {
    border-color: var(--stk-control-border-color);
    box-shadow: var(--stk-control-shadow);
  }

  &:active {
    background-color: var(--stk-control-active-background-color);
    box-shadow: none;
  }

  & + :deep(.tw-absolute) {
    pointer-events: none;
  }
}

.clipboard-icon {
  color: var(--stk-primary);
  opacity: 0;
  position: absolute;
  right: 100%;
  margin-top: 0.25em;
  margin-right: 1em;
  transition-duration: var(--stk-transition-duration);
  transition-property: opacity;
}

@media print {
  .component {
    --spacing: 0;

    &.is-null {
      &.hide-empty {
        display: none !important;
      }
    }

    &.print-mode-all {
      &.is-top-level {
        display: block;
      }
    }

    &.is-object {
      gap: 0.25rem;
      grid-template-columns: repeat(3, 1fr);
    }

    &.is-display {
      align-items: center;
      flex-direction: row;
      justify-content: start;
      padding: 0;
      width: 100%;

      .component-header {
        flex-shrink: 0;
        width: 14em;
      }
    }

    &.is-null {
      opacity: 0.3;
    }
  }

  .component-header.is-ids {
    background: none;
    padding: 0;
    margin: 0.5rem 0;
  }
}
