
.load-save {
  margin-bottom: 15px;
  display: flex;
  font-size: 17px;

  .portfolio-values-button {
    margin-left: 5px;
  }
}
