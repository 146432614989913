
.esgcard {
  .inclusion-switch.switch .check::before {
    // background-color: green;
  }
  .field.toggle-widget .switch-widget.b-tooltip {
    .tooltip-content {
      top: 50px;
    }
  }
}
