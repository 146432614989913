
.client-search-autocomplete {
  background-color: #2b2b2b !important;
  border-color: #444 !important;
  color: #ffffff !important;
}
.client-search-autocomplete input {
  background-color: #2b2b2b !important;
  border-color: #444 !important;
  color: #ffffff !important;
}
.client-search-autocomplete .o-acp__menu {
  background-color: #2b2b2b !important;
  border-color: #444 !important;
}
.client-search-autocomplete .o-acp__item {
  background-color: #2b2b2b !important;
  color: #ffffff !important;
}
.client-search-autocomplete .media-content {
  background-color: #2b2b2b !important;
  color: #ffffff !important;
}
.client-search-autocomplete .media-content .is-size-7 {
  color: #ffffff !important;
}


