
.slide-h-enter-active,
.slide-h-leave-active,
.slide-v-enter-active,
.slide-v-leave-active {
  transition: all 0.5s;
}

.slide-h-enter, .slide-h-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.slide-v-enter, .slide-v-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}
