
.field-card {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  color: black;
  background-color: white;
}

.field-card:hover {
  background-color: #f5f5f5;
  border-color: #bbb;
}

.field-card.is-selected {
  background-color: #3273dc;
  color: white;
}

.field-card.has-existing-value {
  border-left: 4px solid #ffdd57;
}

.custom-field-button {
  border: 1px dashed #ccc;
  color: #666;
}

.custom-field-button:hover {
  border-color: #3273dc;
  color: #3273dc;
  background-color: #f8f9fa;
}

.custom-field-editor {
  padding: 15px;
  margin: 5px 0;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 5px;
}

.field-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.field-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.existing-value {
  font-size: 0.9em;
  color: #666;
}

.field-card.is-selected .existing-value {
  color: #eee;
}

.fields-list {
  max-height: 300px;
  overflow-y: auto;
  margin-top: 15px;
  padding: 5px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}

.modal-card-body .field {
  margin-bottom: 15px;
}

label.label {
  margin-bottom: 0.5rem;
}
