
.stk-icon {
  align-items: center;
  color: inherit;
  display: inline-flex;
  flex-shrink: 0;
  height: var(--stk-icon-dimensions);
  justify-content: center;
  transition-duration: var(--stk-transition-duration);
  transition-property: background-color, color;
  vertical-align: top;
  width: var(--stk-icon-dimensions);
}
