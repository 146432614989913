
.tabs-wrapper {
  background-color: white;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
}

.tabs {
  margin-bottom: 0;
  flex-grow: 1;
}

.tabs ul {
  border-bottom-color: #dbdbdb;
}

.tabs a {
  color: #494949;
  font-weight: 500;
}

.tabs li.is-active a {
  color: #6c7ae0;
  font-weight: 600;
  border-bottom-color: #6c7ae0;
}

/* Style specifically for the toggle wrapper to override tab styles */
.toggle-wrapper {
  /* Isolate it from the tabs styling */
  color: #494949;  
  margin-left: 1rem;
}

/* Override any potential inheritance issues */
.toggle-wrapper :deep(.tw-whitespace-nowrap) {
  color: #494949;
}

.tab-content {
  background-color: white;
  padding-top: 0;
}

/* This ensures there's no gap between tabs and content */
.tab-content > div {
  margin-top: 0;
  padding-top: 0;
}

.box {
  max-width: 350px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
}
