
.wrap {
  align-items: start;
  display: grid;
  gap: 2.5rem;
  position: relative;
}

.layout-half {
  grid-template-columns: 1fr 1fr;
}

.layout-fixed {
  grid-template-columns: 1fr 30rem;
}

.layout-hidden {
  grid-template-columns: 1fr;

  .side {
    display: none;
  }
}

.main {
  overflow: hidden;
}

.side {
  position: sticky;
  top: 0;
}

.pdf-viewer {
  aspect-ratio: 918 / 1188;
  position: relative;
  width: 100%;
  box-shadow: 0 0 0 1px var(--stk-border);
}

.pdf-viewer canvas {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.highlight {
  position: absolute;
  background-color: rgba(255, 255, 0, 0.3);
  border: 2px solid #ffd700;
  transition: all 0.2s ease;
  pointer-events: none;
}

.page-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.tabs {
  margin-bottom: 1.5rem;
}

.tabs ul {
  border-bottom-color: #dbdbdb;
}

.tabs li.is-active a {
  border-bottom-color: #485fc7;
  color: #485fc7;
}

.source-preview-container {
  position: relative;
  display: inline-block;
}

.source-preview {
  position: absolute;
  left: -320px;
  top: 0;
  transform: none;
  z-index: 1000;
  background: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 320px;
  cursor: pointer; /* Add pointer cursor to indicate clickability */
}

.preview-content {
  position: relative;
  width: 300px;
  height: auto;
  overflow: hidden;
}

.preview-content canvas {
  width: 100%;
  height: auto;
  display: block;
}

.preview-highlight {
  position: absolute;
  pointer-events: none;
  background: rgba(255, 255, 0, 0.3);
  border: 2px solid #ffd700;
  z-index: 1;
  transform-origin: top left;
}

/* Remove the bridge element since we want the preview to disappear when not hovering */
.source-preview::before {
  content: '';
  position: absolute;
  top: 50%;
  left: auto;
  right: -8px;
  transform: translateY(-50%);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid white;
  border-right: none;
  filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.1));
}

.table-container {
  margin: 1rem 0;
  max-width: 100%;
  overflow-x: auto;
}

.table {
  background-color: inherit;
  font-size: 0.9em;
  width: 100%;
}

/* .table th,
.table td {
  padding: 0.5em 0.75em;
  vertical-align: middle;
  white-space: nowrap;
} */

.table th,
.table td {
  padding: 0.5em 0.75em;
  vertical-align: middle;
  white-space: normal; /* Allow text to wrap */
  overflow-wrap: break-word; /* Break words that are too long */
  word-break: break-word; /* Fallback for older browsers */
}

.subtitle {
  color: #363636;
  font-weight: 600;
}

/* .table-cell {
  position: relative;
} */

.source-preview {
  position: absolute;
  z-index: 100;
  background: white;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  /* Position the preview to the right of the cell */
  left: 100%;
  top: 0;
}

.preview-content {
  position: relative;
  width: 300px;
}

.preview-highlight {
  position: absolute;
  background-color: rgba(255, 255, 0, 0.3);
  border: 2px solid rgba(255, 165, 0, 0.5);
  pointer-events: none;
}

/* Ensure table cells with previews don't get cut off */
.table-container {
  overflow: visible;
}

/* Add horizontal scroll for the table itself */
.table-container table {
  overflow-x: auto;
  display: block;
  max-width: 100%;
}

/* Ensure proper positioning context for preview */
.table th,
.table td {
  position: relative;
}

/* width for table cells */
.table-cell {
  position: relative;
  max-width: 500px; /* Set a reasonable width for headers */
  word-wrap: break-word; /* Break long words to fit within the width */
  white-space: normal; /* Allow text to wrap to the next line */
  text-align: center; /* Optional: Center-align text for better readability */
}

/* Add styles for the columns layout */
.pdf-display {
  height: calc(100vh - 150px); /* Adjust to fit viewport */
}

.pdf-display-pane {
  overflow-y: auto;
}

.highlight-canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.row {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.row-label {
  color: var(--stk-text-strong);
  width: 16em;
}

.row-input {
  flex-grow: 1;
}

.field-edited {
  border-color: #3273dc;
  outline: 1px solid #3273dc;
  background-color: rgba(50, 115, 220, 0.05);
}
