
.logo {
  display: flex;
  width: 9rem;
  height: 2.25rem;
  justify-content: start;
  align-items: center;
}

.image {
  object-fit: contain;
  max-height: 100%;
}
