
.selected {
  font-weight: bold;
  color: blue;
}
.risk-meter {
  position: relative;
  width: 200px;
  height: 100px;
}

.meter-track {
  stroke: #ddd;
  stroke-width: 2;
  fill: none;
}

.meter-fill {
  stroke: #4c51bf;
  stroke-width: 2;
  fill: none;
  stroke-dasharray: 44;
  transition: stroke-dashoffset 0.3s ease;
}

.risk-score {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
  color: #4c51bf;
}
.risk-score-2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4c51bf;
}
