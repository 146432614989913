
.intro {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to right, #EBF4FF, #E5E8FE, #FCE7F3);
  color: #1a202c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  z-index: 9999;
}

.intro__content {
  max-width: 80%;
  text-align: center;
}

.intro__avatar {
  width: 100px;
  height: 100px;
  margin-bottom: 2rem;
}

.intro__text {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.intro__skip {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  background: #4F46E5;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s ease;
}

.intro__skip:hover {
  background-color: #4338CA;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
