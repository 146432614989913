
.screens {
  .title {
    margin-bottom: 0;
  }

  .card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  column-gap: 1rem;
  row-gap: 1rem;
  }
  .esgcard {
    width: 15rem;
    height: 16rem;
    border: 1px solid;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    display: flex;
  }
  .esgcard.active {
    box-shadow: inset 0px 0px 18px 12px rgba(0,0,0,0.56);
  }
  .esgcard.not-applicable {
    opacity: 0.5;
    pointer-events: none;
  }
  .esgcard.not-applicable.applied {
    pointer-events: all;
  }
  .esgcard .title {
    cursor: pointer;
  }
  .b-slider .b-slider-tick-label {
    top: calc(1rem / 2 + 2px) !important;
    font-size: 1rem;
  }
  .esgissue {
    cursor: pointer;
  }
  .b-slider-track {
    background: silver;
  }
  .esgcard .b-slider.is-primary .b-slider-fill {
    background: whitesmoke !important;
  }
  .switch input[type=checkbox]:checked+.check {
    background: #71c3d2 !important;
  }
  .switch {
    position: relative;
    top: 15px;
    left: 10px;
  }
  .icon {
    cursor: pointer;
  }
  .notification {
    padding: 1.25rem 1.5rem 2.25rem 1.5rem !important;
  }
  .load-save.buttons  {
    & > div {
      margin-left: 15px;
    }
  }
  .filterflex {
    display: flex;
    justify-content: left;

    .control {
      margin-left: 5px;
    }
    .control:first-child {
      margin-left: 0px;
    }
  }

}
