
.grid-container {
  display: grid;
  grid-template-columns: auto repeat(3, 1fr);
  gap: 2em;
  padding: 2em;
}

/* Style for the first column (labels) */
.grid-container > :nth-child(4n + 1) {
  grid-column: 1;
}

/* No Net Taxes column */
.grid-container > :nth-child(4n + 2) {
  grid-column: 2;
  background-color: #3273dc;
  color: white !important;
  min-width: 8em;
}
.grid-container > :nth-child(4n + 2) >  p.title {
  color: white !important;
}

/* Gradual Budgeted column */
.grid-container > :nth-child(4n + 3) {
  grid-column: 3;
  background-color: #eef3fc;
  min-width: 8em;
}

/* Instant Budgetless column */
.grid-container > :nth-child(4n + 4) {
  grid-column: 4;
  background-color: white;
  border: 2px solid #3273dc;
  min-width: 8em;
}

/* Remove gaps within columns */
.grid-container > * {
  margin: 0;
  padding: 1em;
}

/* Title row styling */
.grid-container > :nth-child(-n+4) {
  font-weight: bold;
  font-size: 1.2em;
  align-self: start;
}

/* Align content vertically */
.grid-container > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notification {
  margin-bottom: 1rem;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
