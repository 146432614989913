
.stk-dropdown-checkbox {
  border-radius: 0.5em;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.25rem 0.5rem;
  transition-duration: var(--stk-transition-duration);
  transition-property: background-color;

  &:hover {
    background-color: var(--stk-primary-95);
  }
}
