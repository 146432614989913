
@keyframes strong-orange-glow {
   0% {
     box-shadow: 0 0 15px rgba(255, 140, 0, 0.4);
   }
   50% {
     box-shadow: 0 0 30px rgba(255, 140, 0, 0.7);
   }
   100% {
     box-shadow: 0 0 15px rgba(255, 140, 0, 0.4);
   }
 }

 .sidebar-container {
   position: relative;
   padding: 10px;
   margin: 10px;
 }

 :deep(.area-sidebar) {
   animation: strong-orange-glow 6s ease-in-out infinite;
   border-radius: 8px;
   overflow: hidden;
 }

 .sidebar {
   background: rgba(255, 255, 255, 0.95);
   border-radius: 8px;
   transition: all 0.3s ease;
 }

 .sidebar:hover {
   transform: translateY(-5px);
 }
 